import api from "./api.js";

const Ginalytics = {
  register: async function (name, payload, param_2, param_3, param_4, param_5) {
    if (window.location.href.includes("http://localhost:")) {
      console.warn(
        "Event " + name + " not registered: Localhost mode",
        payload,
        param_2,
        param_3,
        param_4,
        param_5
      );
      return;
    }

    try {
      if (!payload) {
        payload = {};
      }

      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);

      if (Array.from(params).length > 0) payload.search_params = {};

      params.forEach((value, key) => {
        payload.search_params[key] = value;
      });

      const body = {
        event_name: name,
        payload: payload || null,
        param_1: "hub",
        param_2,
        param_3,
        param_4,
        param_5,
      };

      const result = await api.post("/ginalytics", body);
      return !!result?.data?.result;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  getUserCookie() {
    const cookie = document.cookie?.split("gid=")[1];

    return cookie;
  },

  initiateGID() {
    const stored = this.getUserCookie();

    if (!stored) {
      document.cookie =
        "gid=" +
        btoa(Math.floor(Math.random() * 100000000000000000000)) +
        "; domain=.ginastee.com; samesite=None; secure";
    }

    const _register = this.register;

    window.addEventListener("blur", function () {
      _register(
        "window_blur",
        {
          location: window.location.href,
        },
        window.location.href
      );
    });
  },

  registerError(name, payload) {
    this.register("error", payload, name, "hub");
  },
};

export default Ginalytics;
