import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import "./styles2.css";

import * as Material from "@mui/material";

import HeaderNavigation from "../../components/HeaderNavigation";

import SendIcon from "@mui/icons-material/Send";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import RoundedWrite from "../../assets/draws/rounded-write.svg";
import RoundedPeople from "../../assets/draws/rounded-people.svg";
import RoundedPhone from "../../assets/draws/rounded-phone.svg";
import Arrow from "../../assets/draws/arrow.svg";
import girlwithphone from "../../assets/banners/girlwithphone.png";

import { ReactComponent as Femalebodybuilder } from "../../assets/banners/hero-bg.svg";
import femalebodybuildersmall from "../../assets/banners/femalebodybuildersmall.png";

import { ReactComponent as Bodyofyourdreams } from "../../assets/texts/body-of-your-dreams.svg";
import { ReactComponent as LinearScrollAnimation } from "../../assets/draws/scroll-effect-1.svg";

import { ReactComponent as AppleIcon } from "../../assets/custom-icons/Apple.svg";
import { ReactComponent as AndroidIcon } from "../../assets/custom-icons/Android.svg";

import AppViews from "../../assets/draws/app-views.png";

import trainersbg from "../../assets/banners/maninexercise.png";

import scrollanimation from "../../assets/animations/scroll-1.webm";

import linearlines from "../../assets/draws/linear-lines.svg";

import smoke1 from "../../assets/bg-effects/p-smoke-1.svg";
import smoke2 from "../../assets/bg-effects/p-smoke-2.svg";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import CustomerFAQArea from "../../components/CustomerFAQArea";
import Footer from "../../components/Footer";

import Ginalytics from "../../helpers/ginalytics.js";

import { useInView } from "react-intersection-observer";

import { environment } from "../../config.js";

import Semicircle from "../../assets/draws/semicircle.svg";

import TrainerCard from "../../components/TrainerCard";

const trainers = [
  {
    trainerName: "Jo\u00e3o Porto",
    birthday: "1988-04-12",
    slug: "WTNvS2Yva1lBWm12L1ZuZEFrMllTNjIrWk42NnVPQjRKNk0xNG56WHQzUT0=",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580033/depoiments/jmfu2sjt3rhebtfepe1rxb12jw.png",
      "https://assets.ginastee.com/trainerMedia/1580033/depoiments/87hc9fe6rlzfvlj9lecdmyzpmb.png",
    ],
    welcomeVideo: "",
    cref: "043980-G/RJ",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580033/avatars/hfnsm8wrl7jkrg1jhph3ht02qu.jpeg",
    longDescription:
      "\ud83c\udfc6A 13 anos ajudando pessoas nos seus objetivos fitness. \n\ud83d\udcaa Ajudo voc\u00ea a mudar sua percep\u00e7\u00e3o sobre ser saud\u00e1vel e sair do sedentarismo sem sofrimento.\n\ud83c\udfaf Identifico o seu objetivo e coloco 13 anos de experi\u00eancia para voc\u00ea alcan\u00e7a-los.",
    shortDescription:
      "Te ajudo a emagrecer e ter mais sa\u00fade colocando 13 de experi\u00eancia a sua disposi\u00e7\u00e3o.",
    specialties: ["Perda de peso", "Sa\u00fade"],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "Plano Mensal de treinamento",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 50,
        id: 26100006,
      },
    ],
  },
  {
    trainerName: "Danrley Santos",
    birthday: "1998-12-27",
    slug: "cFVPZFExd1IzNHpwK2ZraXBmOUxZQzc4d0lNNkpGLzI5ejljcWpwM1RKdz0=",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/asdnbqavg8uawhxq0vdyqrzbkx.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/v136xmc1qxzqh8wcn9vre0xqum.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/4iekppov3rvzkg9oft3r4hnlrn.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/6dv733vvinuvvjofxhuequhygk.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/ltcbp2fdmbintqlx1ftsrrfix0.png",
    ],
    welcomeVideo:
      "https://assets.ginastee.com/trainerMedia/1580011/welcomeVideos/frwmmx0j62qp468otezrf5mwhx.mp4",
    cref: "048049-G/MG",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580011/avatars/zugvi3ffi3zxdwjimj217phpjd.jpeg",
    longDescription:
      "\ud83d\udcaa 6 anos ajudando pessoas a perder gordura\n\ud83c\udfc6 Mais de 100 alunos ajudados\nAjudo voc\u00ea perder gordura mais f\u00e1cil do que se voc\u00ea estivesse tentando sozinho",
    shortDescription:
      "Te ajudo a alcan\u00e7ar seus objetivos colocando todo meu conhecimento a sua disposi\u00e7\u00e3o.",
    specialties: ["Perda de peso", "Sa\u00fade"],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "Plano mensal",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 97,
        id: 26100002,
      },
    ],
  },
  {
    trainerName: "Keronlayne Corr\u00eaa",
    slug: "SXZzdGh1SjIxVHRTNlFlV3huZGpDbGNXYko3d3FJMTBEUlh3R2lZZzE0RT0=",
    birthday: "1993-12-16",
    depoiments: [],
    welcomeVideo: null,
    cref: " ",
    verified: 0,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580037/avatars/w3tuyhqcgjn8lafc5cpxcfct0v.jpeg",
    longDescription:
      "Ol\u00e1 meu nome \u00e9 Keronlayne tenho 29 anos e sou graduando em Educa\u00e7\u00e3o F\u00edsica (Bacharelado) e iniciei minhas atividades profissionais como instrutora dr muscula\u00e7\u00e3o  em 2022. Desde ent\u00e3o busco metodologias e treinamentos personalizados,workshops.",
    shortDescription:
      "Presto Consultoria online e treinamentos personalizados.",
    specialties: [
      "Perda de peso",
      "Ganho de massa magra",
      "Tratamento de les\u00f5es",
      "Sa\u00fade",
      "Est\u00e9tica",
    ],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "CONSULTORIA ONLINE",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 150,
        id: 26100003,
      },
    ],
  },

  {
    trainerName: "Marcelo Buri",
    birthday: "1988-07-15",
    slug: "WUhEYWgrZG9MVFA5YjdXVHN0TjNwL2EzSmtNTnFrd2tLMmxSeDNvMTV4QT0=",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/d2sjemfhlantwt6m0nltuc2v98.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/qflgrrnnzegmgs9qeduvpndnhi.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/k8eqqelgqzajw7ugqtouy4t15y.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/qmmgyia9fyarc9wmoisq7gjt2h.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/uuwjxjuuuwoos0vzp7dvqprnv5.jpeg",
    ],
    welcomeVideo:
      "https://assets.ginastee.com/trainerMedia/1580000/welcomeVideos/pg4ooj7ovnahhrxigyn6etflbg.mp4",
    cref: "046282-G/MG",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580027/avatars/zasm13dqhh2giivkbmermzcjmj.jpeg",
    longDescription:
      "Perca peso e melhore a sua auto estima com treinamento personalizado que j\u00e1 ajudou mais de 100 pessoas a conquistar seus objetivos. \nMais de 15 anos de experi\u00eancia em muscula\u00e7\u00e3o.\nSeu Personal Trainer e Nutricionista  em uma pessoa s\u00f3",
    shortDescription:
      "Perca peso e melhore a  auto estima com treinamento personalizado que j\u00e1 ajudou mais de 100 pessoas.",
    specialties: [
      "Perda de peso",
      "Ganho de massa magra",
      "Est\u00e9tica",
      "Sa\u00fade",
    ],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "Plano Mensal (S\u00f3 Treinamento)",
        paymentMethods: ["CREDIT_CARD", "PIX"],
        cycle: "MONTHLY",
        price: 120,
        id: 26100008,
      },
      {
        name: "Plano Anual (S\u00f3 Treinamento) (-31%)",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "YEARLY",
        price: 997,
        id: 26100009,
      },
    ],
  },

  {
    trainerName: "Sanclei Maito",
    slug: "c2syT0tzdkFWcXlBM0VpVTRrYTF3SndsZ20wOVhLZC8wV1JUZksxV09xTT0=",
    birthday: "1982-03-28",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/n0kzlvau8tfgiqtbnkul2nv1sn.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/lfkbjudyung78qezo7rfsopzpm.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/hpfixo1rppqvfnmlis6lfdycsb.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/tgtntnjc5jx3tdkodnaa4ued5s.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/3pndrakt30vfhlwu4hhojasydl.jpeg",
    ],
    welcomeVideo: null,
    cref: "060826-G/SP",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580042/avatars/fqfcvofoxrmm3yafh5k2amugbh.jpeg",
    longDescription:
      "\ud83c\udfc3\u200d\u2640\ufe0f Supere a ansiedade e alcance o peso desejado com a corrida!\nJunte-se ao nosso grupo e descubra como iniciar nessa jornada.",
    shortDescription:
      "Saia do zero aos 3 km em at\u00e9 8 semanas e diminua a ansiedade e perca peso.",
    specialties: ["Perda de peso", "Sa\u00fade"],
    genderPreferences: "MALE",
    plans: [
      {
        name: "Segue Correndo",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 79.9,
        id: 26100011,
      },
    ],
  },
];

if (environment === "home") {
  trainers.unshift({
    trainerName: "Ginastee Teste",
    birthday: "1988-04-12",
    slug: "Y1VYdmh5Mys4SFM1RzB1V0RkcXUvMFJEcUk2WkoyVVdxUWdSZ2VDOGkyYz0=",
    welcomeVideo: "",
    cref: "--",
    verified: 1,
    avatar: "/static/media/M_BLACK_LOGO.b78b16a993772362291b5e48600f91a8.svg",
    longDescription: "Personal teste | Ginastee",
    shortDescription: "",
    specialties: ["Perda de peso"],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "Novo plano",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 100,
        id: 26100004,
      },
    ],
  });
}

export default function Hub2() {
  const sectionRefs = React.useRef({
    hero: React.createRef(),
    benefits: React.createRef(),
    trainers: React.createRef(),
    download: React.createRef(),
    faq: React.createRef(),
  });

  const trainersSectionRef = React.useRef();

  function navigateTo(section) {
    sectionRefs.current[section].current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      document.querySelector("main")?.scrollBy({
        top: -95,
        behavior: "smooth", // Make the second scroll smooth
      });
    }, 1200);
  }

  return (
    <main className="hp">
      <HeaderNavigation
        links={[
          { text: "Home", onClick: () => navigateTo("hero") },
          { text: "Benefícios", onClick: () => navigateTo("benefits") },
          { text: "Treinadores", onClick: () => navigateTo("trainers") },
          { text: "Dúvidas", onClick: () => navigateTo("faq") },
        ]}
        ctaBtn={{
          text: "Baixar",
          onClick: () => navigateTo("download"),
        }}
      />
      <Hero navigateTo={navigateTo} ref={sectionRefs.current.hero} />
      <Benefits ref={sectionRefs.current.benefits} />
      <TrainerList ref={sectionRefs.current.trainers} />
      <AppDemo ref={sectionRefs.current.download} />
      <div className="faq-area" ref={sectionRefs.current.faq}>
        <CustomerFAQArea mode="dark" />
      </div>
      <SuperShortCTA onCTAClick={() => navigateTo("trainers")} />
    </main>
  );
}

const Hero = React.forwardRef(({ navigateTo }, ref) => {
  function ScrollAnimation() {
    return (
      <video autoPlay loop muted className="scroll-animation">
        <source src={scrollanimation} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    );
  }

  return (
    <section className="hero" ref={ref}>
      <div className="cta-area">
        <div className="cta-container">
          <img src={linearlines} className="linear-decoration" />
          <Bodyofyourdreams className="boyd" />
          <h4 className="pl-1 mt-1">
            Alcance a sua melhor forma física e mental, encurtando o caminho com
            o treinador perfeito para você!
          </h4>
          <div className="hero-btns mt-2">
            <Material.Button
              onClick={() => navigateTo("trainers")}
              className="header-cta cta-btn block-middle px-5 py-2 mt-2"
              variant="contained"
              color="secondary"
            >
              Começar minha transformação
            </Material.Button>
            <Material.Button
              onClick={() => navigateTo("download")}
              className="header-cta cta-btn secondary-btn block-middle px-5 py-2 mt-2"
              variant="contained"
              color="secondary"
            >
              Baixar o app
            </Material.Button>
          </div>
        </div>
      </div>

      {window.innerWidth > 720 ? (
        <Femalebodybuilder className="bg" />
      ) : (
        <img src={femalebodybuildersmall} className="bg" />
      )}
      <span className="hero-fade"></span>
      <img src={smoke1} className="smoke-1"></img>
      <img src={smoke2} className="smoke-2"></img>

      <ScrollAnimation />
    </section>
  );
});

const Benefits = React.forwardRef((props, ref) => {
  const sections = [
    {
      title: "Perca gordura e ganhe massa magra",
      subtitle:
        "Treinos focados em te deixar com o corpo estético e saudável, como você sempre sonhou",
    },
    {
      title: "Mais resultados com menos esforço",
      subtitle:
        "Com as técnicas certas, você direciona seu esforço para o que realmente vai te trazer resultados",
    },
    {
      title: "54% mais foco",
      subtitle:
        "Treinar com o Ginastee reduz drásticamente as chances de você desistir do seu objetivo",
    },
  ];

  function Section({ title, subtitle }) {
    const [ref, inView] = useInView({
      triggerOnce: true, // Only trigger once when the element comes into view
      rootMargin: "-100px 0px",
    });

    return (
      <div className={`benefit ${inView ? "visible" : ""}`} ref={ref}>
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </div>
    );
  }

  return (
    <section className="benefits" ref={ref}>
      <div className="gwf">
        <img src={girlwithphone} />
      </div>

      <div className="bsections mt-8">
        {sections.map((section, i) => (
          <Section title={section.title} subtitle={section.subtitle} key={i} />
        ))}
      </div>
    </section>
  );
});

const TrainerList = React.forwardRef((props, ref) => {
  const [displayFullList, setDisplayFullList] = React.useState(
    window.innerWidth > 720
  );
  const [firstRender, setFirstRender] = React.useState(false)

  React.useEffect(() => {
    if (displayFullList && firstRender) {
      setTimeout(() => {
        document
          .querySelector(".trainers .ptc:nth-child(4)")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [displayFullList]);

  React.useEffect(() => {
    if(!firstRender) {
      setFirstRender(true)
    }
  }, [])

  function getTrainers() {
    if (displayFullList) return trainers;
    return trainers.slice(0, 3);
  }

  const trainersList = getTrainers();

  return (
    <section className="trainers" key={displayFullList}>
      <img src={trainersbg} className="bg" />

      <section className="tl" ref={ref}>
        <Material.Typography variant="h2" className="section-title">
          Escolha um dos personal trainers abaixo e comece hoje mesmo
        </Material.Typography>
        <Material.Typography variant="h4" className="section-title mb-5">
          Agora é a sua chance de finalmente entrar em forma, e conquistar o
          corpo dos seus sonhos{" "}
        </Material.Typography>

        <div className="trainers-available">
          {trainersList.map((trainer, i) => (
            <TrainerCard {...trainer} key={i} position={i} />
          ))}
        </div>
        {!displayFullList && (
          <Material.Button
            onClick={() => setDisplayFullList(true)}
            className="px-5 py-2 load-more-trainers secondary-btn mt-6 cta-btn"
            variant="contained"
            color="secondary"
          >
            <AutorenewIcon />
            Carregar mais treinadores
          </Material.Button>
        )}
      </section>
    </section>
  );
});

const AppDemo = React.forwardRef((props, ref) => {
  function onCTAClick(platform) {
    const url =
      platform === "Android"
        ? "https://play.google.com/store/apps/details?id=com.ginastee"
        : "https://apps.apple.com/br/app/ginastee/id6448853593";

    window.open(url);
  }

  return (
    <section className="app-demo mt-8 mb-6" ref={ref}>
      <Material.Typography variant="h2" className="mb-1">
        Baixe o aplicativo 100% gratuito, e comece seus treinos agora mesmo
      </Material.Typography>
      <Material.Typography variant="h5">
        Todo o seu progresso em um só lugar, na palma da sua mão!
      </Material.Typography>

      <div className="download-btns">
        <Material.Button
          onClick={() => onCTAClick("IOS")}
          className="px-5 py-2 mt-6 cta-btn"
          variant="contained"
          color="secondary"
        >
          <AppleIcon />
          Baixar para iPhone
        </Material.Button>
        <Material.Button
          onClick={() => onCTAClick("Android")}
          className="px-5 py-2 mt-6 cta-btn"
          variant="contained"
          color="secondary"
        >
          <AndroidIcon />
          Baixar para Android
        </Material.Button>
      </div>

      <img
        src={AppViews}
        className="app-views mt-3"
        alt="Telas do aplicativo Ginastee"
      />
    </section>
  );
});

const SuperShortCTA = ({ onCTAClick }) => {
  return (
    <div className="mt-8 scta-area">
      <img src={Semicircle} className="semicircle" />
      <div className="scta px-2">
        <Material.Typography variant="h1">
          Comece agora a conquistar o corpo que você sempre sonhou!{" "}
        </Material.Typography>

        <Material.Typography variant="h4" className="mt-1">
          Chega de adiar o sonho de entrar em forma, e cuidar da sua saúde.
          Escolha um personal trainer e comece hoje mesmo
        </Material.Typography>

        <Material.Button
          onClick={onCTAClick}
          className="header-cta cta-btn light block-middle px-5 py-2 mt-2"
          variant="contained"
          color="secondary"
        >
          Iniciar o programa corpo dos sonhos
        </Material.Button>

        <Footer mode="dark" />
      </div>
    </div>
  );
};
