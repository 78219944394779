import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import "./styles2.css";

import * as Material from "@mui/material";

import HeaderNavigation from "../../components/HeaderNavigation/index.js";

import SendIcon from "@mui/icons-material/Send";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import RoundedWrite from "../../assets/draws/rounded-write.svg";
import RoundedPeople from "../../assets/draws/rounded-people.svg";
import RoundedPhone from "../../assets/draws/rounded-phone.svg";
import Arrow from "../../assets/draws/arrow.svg";
import girlwithphone from "../../assets/banners/girlwithphone.png";
import partners from '../../assets/logo/partners.png'

import { ReactComponent as Femalebodybuilder } from "../../assets/banners/hero-bg.svg";
import femalebodybuildersmall from "../../assets/banners/femalebodybuildersmall.png";
import fitplacetowork from "../../assets/banners/fit-place-to-work.png";

import { ReactComponent as TransformAthletes } from "../../assets/texts/transform-athletes.svg";
import { ReactComponent as LinearScrollAnimation } from "../../assets/draws/scroll-effect-1.svg";

import { ReactComponent as AppleIcon } from "../../assets/custom-icons/Apple.svg";
import { ReactComponent as AndroidIcon } from "../../assets/custom-icons/Android.svg";

import AppViews from "../../assets/draws/app-views.png";

import trainersbg from "../../assets/banners/maninexercise.png";

import scrollanimation from "../../assets/animations/scroll-1.gif";

import linearlines from "../../assets/draws/linear-lines.svg";

import smoke1 from "../../assets/bg-effects/p-smoke-1.svg";
import smoke2 from "../../assets/bg-effects/p-smoke-2.svg";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import CustomerFAQArea from "../../components/CustomerFAQArea/index.js";
import Footer from "../../components/Footer/index.js";

import Ginalytics from "../../helpers/ginalytics.js";

import { useInView } from "react-intersection-observer";

import { environment } from "../../config.js";

import Semicircle from "../../assets/draws/semicircle.svg";

export default function Company() {
  const sectionRefs = React.useRef({
    hero: React.createRef(),
    benefits: React.createRef(),
    steps: React.createRef(),
    download: React.createRef(),
    faq: React.createRef(),
  });

  const trainersSectionRef = React.useRef();

  function navigateTo(section) {
    sectionRefs.current[section].current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      document.querySelector("main")?.scrollBy({
        top: -95,
        behavior: "smooth", // Make the second scroll smooth
      });
    }, 1200);
  }

  return (
    <main className="hp">
      <HeaderNavigation
        links={[
          { text: "Home", onClick: () => navigateTo("hero") },
          { text: "Benefícios", onClick: () => navigateTo("benefits") },
          { text: "Contato", onClick: () => window.location.href = 'https://wa.me/5531971583279' },
        ]}
        ctaBtn={{
          text: "Baixar",
          onClick: () => navigateTo("download"),
        }}
      />
      <Hero navigateTo={navigateTo} ref={sectionRefs.current.hero} />
      <Benefits ref={sectionRefs.current.benefits} />
      <AppDemo ref={sectionRefs.current.download} />
      <Steps ref={sectionRefs.current.steps} />
      <SuperShortCTA onCTAClick={() => window.location.href = 'https://wa.me/5531971583279'} />
    </main>
  );
}

const Hero = React.forwardRef(({ navigateTo }, ref) => {
  function ScrollAnimation() {
    return (
      <img src={scrollanimation} className="scroll-animation"/>
    );
  }

  return (
    <section className="hero" ref={ref}>
      <div className="cta-area">
        <div className="cta-container">
          <img src={linearlines} className="linear-decoration" />
          <TransformAthletes className="boyd" />
          <h4 className="pl-1 mt-1">
            Aumente a produtividade e satisfação de seus colaboradores, os
            engajando com um programa fitness customizado e eficaz.
          </h4>
          <div className="hero-btns mt-2">
            <Material.Button
              onClick={() => window.location.href = 'https://wa.me/5531971583279'}
              className="header-cta cta-btn block-middle px-5 py-2 mt-2"
              variant="contained"
              color="secondary"
            >
              Falar com um consultor
            </Material.Button>
          </div>
        </div>
      </div>

      {window.innerWidth > 720 ? (
        <Femalebodybuilder className="bg" />
      ) : (
        <img src={femalebodybuildersmall} className="bg" />
      )}
      <span className="hero-fade"></span>
      <img src={smoke1} className="smoke-1"></img>
      <img src={smoke2} className="smoke-2"></img>

      <ScrollAnimation />
    </section>
  );
});

const Benefits = React.forwardRef((props, ref) => {
  const sections = [
    {
      title: "Aumente o foco dos colaboradores em 41%",
      subtitle:
        "Funcionários saudáveis tem em média 41% mais atenção do que colaboradores sedentários",
    },
    {
      title: "Reduza 30% dos custos em planos de saúde",
      subtitle:
        "Quando um funcionário passa a se alimentar adequadamente, e praticar atividade física, ele gasta em média 30% a menos com planos de saúde",
    },
    {
      title: "Crie um clima organizacional saudável",
      subtitle:
        "Dê aos seus colaboradores o benefício que eles precisam para amarem a sua empresa",
    },
  ];

  function Section({ title, subtitle }) {
    const [ref, inView] = useInView({
      triggerOnce: true, // Only trigger once when the element comes into view
      rootMargin: "-100px 0px",
    });

    return (
      <div className={`benefit ${inView ? "visible" : ""}`} ref={ref}>
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </div>
    );
  }

  return (
    <section className="benefits" ref={ref}>
      <div className="gwf">
        <img src={girlwithphone} />
      </div>

      <div className="bsections mt-8">
        {sections.map((section, i) => (
          <Section title={section.title} subtitle={section.subtitle} key={i} />
        ))}
      </div>
    </section>
  );
});

const AppDemo = React.forwardRef((props, ref) => {
  function onCTAClick(platform) {
    const url =
      platform === "Android"
        ? "https://play.google.com/store/apps/details?id=com.ginastee"
        : "https://apps.apple.com/br/app/ginastee/id6448853593";

    window.open(url);
  }

  return (
    <section className="app-demo mt-8 mb-6" ref={ref}>
      <Material.Typography variant="h2" className="mb-1">
        Mais do que um aplicativo com tudo para você ter um corpo saudável
      </Material.Typography>
      <Material.Typography variant="h5">
        Uma ferramenta para cuidar do corpo e da alma, com treinos completos,
        conteúdos nutricionais, receitas fitness, e muito mais...
      </Material.Typography>

      <img
        src={AppViews}
        className="app-views mt-3"
        alt="Telas do aplicativo Ginastee"
      />
    </section>
  );
});

const Steps = React.forwardRef((props, ref) => {
  const steps = [
    {
      id: "#01",
      title: "Avaliação física",
      description:
        "Iremos enviar um personal trainer na sua empresa para avaliar a saúde dos colaboradores",
    },
    {
      id: "#02",
      title: "Envio de treinos + dieta",
      description:
        "Após a avaliação, nossos profissionais irão enviar uma dieta e treino para todos os funcionários",
    },
    {
      id: "#03",
      title: "Início do desafio",
      description:
        "Com o desafio iniciado, vamos recorrentemente enviar conteúdos que vão motivar e direcionar os funcionários durante o desafio",
    },
    {
      id: "#04",
      title: "Re-Avaliação física",
      description:
        "No final do desafio, o personal trainer volta para mensurar o progresso durante o período",
    },
    {
      id: "#05",
      title: "Celebração",
      description:
        "Após mensurar os resultados, os colaboradores que tiveram destaque irão receber premiações",
    },
  ];

  return (
    <section className="program-steps mt-8 mb-6" ref={ref}>
      <Material.Typography variant="h2" className="mb-3">
        Colaboradores mais felizes e produtivos em apenas 5 passos
      </Material.Typography>

      {steps.map((step) => (
        <div className="step-block mb-2" ref={ref}>
          <h1>{step.id}</h1>
          <h2>{step.title}</h2>
          <h4>{step.description}</h4>
        </div>
      ))}

      <Material.Typography variant="h2" className="mb-1 mt-5">
        E finalmente, a empresa recebe um prêmio...
      </Material.Typography>

      <img src={fitplacetowork} class='fit-place-to-work'/>

      <Material.Button
              onClick={() => window.location.href = 'https://wa.me/5531971583279'}
              className="header-cta cta-btn block-middle px-5 py-2 mt-6"
              variant="contained"
              color="secondary"
            >
              Falar com um consultor
            </Material.Button>
    </section>
  );
});

const SuperShortCTA = ({ onCTAClick }) => {
  return (
    <div className="mt-8 scta-area">
      <img src={Semicircle} className="semicircle" />
      <div className="scta px-2">
        <Material.Typography variant="h1">
          Comece agora mesmo a tirar seus colaboradores do sedentarismo
        </Material.Typography>

        <Material.Typography variant="h4" className="mt-1">
          Chega de ver seus funcionários desmotivados com a empresa, custos altos com planos de saúde e falta de entrega
        </Material.Typography>

        <Material.Button
          onClick={onCTAClick}
          className="header-cta cta-btn light block-middle px-5 py-2 mt-2"
          variant="contained"
          color="secondary"
        >
          Saber mais detalhes sobre o desafio
        </Material.Button>

        <Material.Typography variant="h4" className="mt-4">
          Agradecimento especial aos nossos parceiros
        </Material.Typography>
        <img src={partners} className="mt-2" style={{
          maxWidth: '250px',
          width: '100%',

        }}/>

        <Footer mode="dark" />
      </div>
    </div>
  );
};
