import React from "react";

import logo from "../../assets/logo/M_WHITE_LOGO_FULL.svg";

import * as Material from "@mui/material";

import "./styles.css";

import { useNavigate } from "react-router-dom";

import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

export default function HeaderNavigation({ onLoginPress, links = [], ctaBtn }) {
  const [opened, setOpened] = React.useState(false);

  const navigate = useNavigate();

  const headerRef = React.useRef();
  const scrollOffset = React.useRef(0);

  React.useEffect(() => {
    const mainElm = document.querySelector("main");
    console.log(mainElm);
    if (!mainElm) return;
    if (opened) {
      mainElm.classList.add("no-scroll");
    } else {
      mainElm.classList.remove("no-scroll");
    }
  }, [opened]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleHeaderBehavior);
  }, []);

  React.useEffect(() => {
    if (opened) {
      document.querySelectorAll(".main-btns-small button")?.forEach((btn) => {
        btn.addEventListener("click", function () {
          setTimeout(() => {
            setOpened(false);
          }, 200)
        });
      });
    }
  }, [opened]);

  function handleHeaderBehavior() {
    const scrollPosition = window.pageYOffset;

    if (scrollPosition === scrollOffset.current || !headerRef.current) return;

    const shouldDisplay =
      scrollPosition < scrollOffset.current || scrollPosition < 250;

    headerRef.current.style.opacity = shouldDisplay ? "1" : "0";
    headerRef.current.style.pointerEvents = shouldDisplay ? "all" : "none";

    scrollOffset.current = scrollPosition;
  }

  return (
    <>
      <header ref={headerRef}>
        <img src={logo} className="logo" alt="Ginastee Logo" />

        <div className="main-btns">
          {links.length === 0 ? (
            <>
              <Material.Button
                className="px-1"
                variant="text"
                color="white"
                size="small"
                onClick={() => {
                  navigate("/personal");
                }}
              >
                Para personal
              </Material.Button>

              <Material.Button
                className="px-1"
                variant="text"
                color="white"
                size="small"
                onClick={() => {
                  window.open(
                    "https://api.whatsapp.com/send/?phone=5531971583279&text&type=phone_number&app_absent=0"
                  );
                }}
              >
                Suporte
              </Material.Button>
            </>
          ) : (
            <>
              {links.map((link, i) => (
                <Material.Button
                  key={i}
                  className="px-1"
                  variant="text"
                  color="white"
                  size="small"
                  onClick={link.onClick}
                >
                  {link.text}
                </Material.Button>
              ))}
            </>
          )}
        </div>

        <Material.Button
          variant="contained"
          color="white"
          size="small"
          startIcon={<LoginIcon />}
          className="btn-login"
          onClick={
            ctaBtn
              ? ctaBtn?.onClick
              : () => {
                  onLoginPress && onLoginPress();
                }
          }
        >
          <span className="btn-login-text">
            {ctaBtn ? ctaBtn.text : "Entrar"}
          </span>
        </Material.Button>

        <Material.IconButton
          className="hamb-menu-btn"
          onClick={() => setOpened(true)}
        >
          <MenuIcon color="white" />
        </Material.IconButton>
      </header>

      {opened && (
        <div className="nav-opened fade">
          <img src={logo} className="logo" alt="Ginastee Logo" />

          <div className="main-btns-small">
            {links.length === 0 ? (
              <>
                <Material.Button
                  className="px-1"
                  variant="text"
                  color="white"
                  size="small"
                  onClick={() => {
                    navigate("/personal");
                  }}
                >
                  Para personal
                </Material.Button>

                <Material.Button
                  className="px-1"
                  variant="text"
                  color="white"
                  size="small"
                  onClick={() => {
                    window.open(
                      "https://api.whatsapp.com/send/?phone=5531971583279&text&type=phone_number&app_absent=0"
                    );
                  }}
                >
                  Suporte
                </Material.Button>
              </>
            ) : (
              <>
                {links.map((link, i) => (
                  <Material.Button
                    key={i}
                    className={"px-1 slide-bottom-fade delay-" + i}
                    variant="text"
                    color="white"
                    size="small"
                    onClick={link.onClick}
                  >
                    <h3>{link.text}</h3>
                  </Material.Button>
                ))}
              </>
            )}

            <Material.Button
              variant="contained"
              color="white"
              size="small"
              startIcon={<LoginIcon />}
              className="btn-login py-2"
              onClick={
                ctaBtn
                  ? ctaBtn?.onClick
                  : () => {
                      onLoginPress && onLoginPress();
                    }
              }
            >
              <span className="btn-login-text">
                {ctaBtn ? ctaBtn.text : "Entrar"}
              </span>
            </Material.Button>

            <Material.Button
              variant="text"
              color="white"
              size="small"
              startIcon={<CloseIcon />}
              className="btn-login mt-3 py-2"
            >
              <span className="btn-login-text">Fechar menu</span>
            </Material.Button>
          </div>
        </div>
      )}
    </>
  );
}
