import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import "./styles.css";
import "../../styles.css";

import * as Material from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import TrainerCard from "../../../../components/TrainerCard";

import RoundedWrite from "../../../../assets/draws/rounded-write.svg";
import RoundedPeople from "../../../../assets/draws/rounded-people.svg";
import RoundedPhone from "../../../../assets/draws/rounded-phone.svg";
import Arrow from "../../../../assets/draws/arrow.svg";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import AppViews from "../../../../assets/draws/app-views.png";

import CustomerFAQArea from "../../../../components/CustomerFAQArea";
import Footer from "../../../../components/Footer";

import Ginalytics from "../../../../helpers/ginalytics.js";

import AppMetrics from "../../../../assets/draws/appmetrics.svg";
import FemaleBanner from "../../../../assets/draws/female-banner.png";
import logo from "../../../../assets/logo/M_BLACK_LOGO.svg";

import Person1 from "../../../../assets/draws/people/run-1.png";
import Person2 from "../../../../assets/draws/people/run-2.png";
import Person3 from "../../../../assets/draws/people/phone-1.png";

import EmagrecimentoBanner from "../../../../assets/draws/emagrecimento-banner.png";
import EmagrecimentoBannerSmall from "../../../../assets/draws/emagrecimento-banner-small.png";

import Semicircle from "../../../../assets/draws/semicircle.svg";

import { environment } from "../../../../config.js";

const trainers = [
  {
    trainerName: "Jo\u00e3o Porto",
    birthday: "1988-04-12",
    slug: "WTNvS2Yva1lBWm12L1ZuZEFrMllTNjIrWk42NnVPQjRKNk0xNG56WHQzUT0=",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580033/depoiments/jmfu2sjt3rhebtfepe1rxb12jw.png",
      "https://assets.ginastee.com/trainerMedia/1580033/depoiments/87hc9fe6rlzfvlj9lecdmyzpmb.png",
    ],
    welcomeVideo: "",
    cref: "043980-G/RJ",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580033/avatars/hfnsm8wrl7jkrg1jhph3ht02qu.jpeg",
    longDescription:
      "\ud83c\udfc6A 13 anos ajudando pessoas nos seus objetivos fitness. \n\ud83d\udcaa Ajudo voc\u00ea a mudar sua percep\u00e7\u00e3o sobre ser saud\u00e1vel e sair do sedentarismo sem sofrimento.\n\ud83c\udfaf Identifico o seu objetivo e coloco 13 anos de experi\u00eancia para voc\u00ea alcan\u00e7a-los.",
    shortDescription:
      "Te ajudo a emagrecer e ter mais sa\u00fade colocando 13 de experi\u00eancia a sua disposi\u00e7\u00e3o.",
    specialties: ["Perda de peso", "Sa\u00fade"],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "Plano Mensal de treinamento",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 50,
        id: 26100006,
      },
    ],
  },
  {
    trainerName: "Danrley Santos",
    birthday: "1998-12-27",
    slug: "cFVPZFExd1IzNHpwK2ZraXBmOUxZQzc4d0lNNkpGLzI5ejljcWpwM1RKdz0=",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/asdnbqavg8uawhxq0vdyqrzbkx.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/v136xmc1qxzqh8wcn9vre0xqum.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/4iekppov3rvzkg9oft3r4hnlrn.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/6dv733vvinuvvjofxhuequhygk.png",
      "https://assets.ginastee.com/trainerMedia/1580000/depoiments/ltcbp2fdmbintqlx1ftsrrfix0.png",
    ],
    welcomeVideo:
      "https://assets.ginastee.com/trainerMedia/1580011/welcomeVideos/frwmmx0j62qp468otezrf5mwhx.mp4",
    cref: "048049-G/MG",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580011/avatars/zugvi3ffi3zxdwjimj217phpjd.jpeg",
    longDescription:
      "\ud83d\udcaa 6 anos ajudando pessoas a perder gordura\n\ud83c\udfc6 Mais de 100 alunos ajudados\nAjudo voc\u00ea perder gordura mais f\u00e1cil do que se voc\u00ea estivesse tentando sozinho",
    shortDescription:
      "Te ajudo a alcan\u00e7ar seus objetivos colocando todo meu conhecimento a sua disposi\u00e7\u00e3o.",
    specialties: ["Perda de peso", "Sa\u00fade"],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "Plano mensal",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 97,
        id: 26100002,
      },
    ],
  },
  {
    trainerName: "Keronlayne Corr\u00eaa",
    slug: "SXZzdGh1SjIxVHRTNlFlV3huZGpDbGNXYko3d3FJMTBEUlh3R2lZZzE0RT0=",
    birthday: "1993-12-16",
    depoiments: [],
    welcomeVideo: null,
    cref: " ",
    verified: 0,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580037/avatars/w3tuyhqcgjn8lafc5cpxcfct0v.jpeg",
    longDescription:
      "Ol\u00e1 meu nome \u00e9 Keronlayne tenho 29 anos e sou graduando em Educa\u00e7\u00e3o F\u00edsica (Bacharelado) e iniciei minhas atividades profissionais como instrutora dr muscula\u00e7\u00e3o  em 2022. Desde ent\u00e3o busco metodologias e treinamentos personalizados,workshops.",
    shortDescription:
      "Presto Consultoria online e treinamentos personalizados.",
    specialties: [
      "Perda de peso",
      "Ganho de massa magra",
      "Tratamento de les\u00f5es",
      "Sa\u00fade",
      "Est\u00e9tica",
    ],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "CONSULTORIA ONLINE",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 150,
        id: 26100003,
      },
    ],
  },

  {
    trainerName: "Marcelo Buri",
    birthday: "1988-07-15",
    slug: "WUhEYWgrZG9MVFA5YjdXVHN0TjNwL2EzSmtNTnFrd2tLMmxSeDNvMTV4QT0=",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/d2sjemfhlantwt6m0nltuc2v98.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/qflgrrnnzegmgs9qeduvpndnhi.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/k8eqqelgqzajw7ugqtouy4t15y.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/qmmgyia9fyarc9wmoisq7gjt2h.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580027/depoiments/uuwjxjuuuwoos0vzp7dvqprnv5.jpeg",
    ],
    welcomeVideo:
      "https://assets.ginastee.com/trainerMedia/1580000/welcomeVideos/pg4ooj7ovnahhrxigyn6etflbg.mp4",
    cref: "046282-G/MG",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580027/avatars/zasm13dqhh2giivkbmermzcjmj.jpeg",
    longDescription:
      "Perca peso e melhore a sua auto estima com treinamento personalizado que j\u00e1 ajudou mais de 100 pessoas a conquistar seus objetivos. \nMais de 15 anos de experi\u00eancia em muscula\u00e7\u00e3o.\nSeu Personal Trainer e Nutricionista  em uma pessoa s\u00f3",
    shortDescription:
      "Perca peso e melhore a  auto estima com treinamento personalizado que j\u00e1 ajudou mais de 100 pessoas.",
    specialties: [
      "Perda de peso",
      "Ganho de massa magra",
      "Est\u00e9tica",
      "Sa\u00fade",
    ],
    genderPreferences: "BOTH",
    plans: [
      {
        name: "Plano Mensal (S\u00f3 Treinamento)",
        paymentMethods: ["CREDIT_CARD", "PIX"],
        cycle: "MONTHLY",
        price: 120,
        id: 26100008,
      },
      {
        name: "Plano Anual (S\u00f3 Treinamento) (-31%)",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "YEARLY",
        price: 997,
        id: 26100009,
      },
    ],
  },

  {
    trainerName: "Sanclei Maito",
    slug: "c2syT0tzdkFWcXlBM0VpVTRrYTF3SndsZ20wOVhLZC8wV1JUZksxV09xTT0=",
    birthday: "1982-03-28",
    depoiments: [
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/n0kzlvau8tfgiqtbnkul2nv1sn.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/lfkbjudyung78qezo7rfsopzpm.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/hpfixo1rppqvfnmlis6lfdycsb.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/tgtntnjc5jx3tdkodnaa4ued5s.jpeg",
      "https://assets.ginastee.com/trainerMedia/1580042/depoiments/3pndrakt30vfhlwu4hhojasydl.jpeg",
    ],
    welcomeVideo: null,
    cref: "060826-G/SP",
    verified: 1,
    avatar:
      "https://assets.ginastee.com/trainerMedia/1580042/avatars/fqfcvofoxrmm3yafh5k2amugbh.jpeg",
    longDescription:
      "\ud83c\udfc3\u200d\u2640\ufe0f Supere a ansiedade e alcance o peso desejado com a corrida!\nJunte-se ao nosso grupo e descubra como iniciar nessa jornada.",
    shortDescription:
      "Saia do zero aos 3 km em at\u00e9 8 semanas e diminua a ansiedade e perca peso.",
    specialties: ["Perda de peso", "Sa\u00fade"],
    genderPreferences: "MALE",
    plans: [
      {
        name: "Segue Correndo",
        paymentMethods: ["PIX", "CREDIT_CARD"],
        cycle: "MONTHLY",
        price: 79.9,
        id: 26100011,
      },
    ],
  },
];

if (environment === "home") {
  trainers.unshift({
    trainerName: "Ginastee Teste",
    birthday: "1988-04-12",
    slug: "Y1VYdmh5Mys4SFM1RzB1V0RkcXUvMFJEcUk2WkoyVVdxUWdSZ2VDOGkyYz0=",
    welcomeVideo: "",
    cref: "--",
    verified: 1,
    avatar:
      "/static/media/M_BLACK_LOGO.b78b16a993772362291b5e48600f91a8.svg",
    longDescription: "Personal teste | Ginastee",
    shortDescription: "",
    specialties: ["Perda de peso"],
    genderPreferences: "BOTH",
    plans: [
      {
        "name": "Novo plano",
        "paymentMethods": [
            "PIX",
            "CREDIT_CARD"
        ],
        "cycle": "MONTHLY",
        "price": 100,
        "id": 26100004
    },
    ],
  });
}

export default function EmagrecimentoV1() {
  const trainersSectionRef = React.useRef();

  const registeredPageViewRef = React.useRef();

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    addSrcInParams();

    if (!registeredPageViewRef.current) {
      Ginalytics.register("page_view", null, "home", "version_ev1");
      registeredPageViewRef.current = true;
    }
  }, [location.href]);

  const addSrcInParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("user_src", "hub");
    queryParams.set("version", "emag-v1");
    const newSearchString = `?${queryParams.toString()}`;
    navigate(newSearchString);
  };

  function navigateToTrainers(placement = null) {
    Ginalytics.register("clicked_on_navigate_to_trainers", null, placement);
    trainersSectionRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <main className="hub-main ev1">
      <img src={logo} className="logo mt-3 mb-4" alt="Ginastee Logo" />

      <Banner onCTAClick={() => navigateToTrainers("hero")} />
      <Benefits />
      <PainAndSolutionArea />
      <Material.Button
        onClick={() => navigateToTrainers("hero")}
        className="block-middle cta-btn px-5 py-2 mt-2"
        variant="contained"
        color="secondary"
      >
        Começar meu acompanhamento
      </Material.Button>

      <img
        src={
          window.screen.width > 720
            ? EmagrecimentoBanner
            : EmagrecimentoBannerSmall
        }
        className="benefits-banner mt-4"
      />

      <TrainersList trainers={trainers} sectionRef={trainersSectionRef} />
      <section className="dark-section mt-5">
        <AppDemo onCTAClick={() => navigateToTrainers("app_demo")} />
        <CustomerFAQArea mode="dark" />
      </section>

      <SuperShortCTA onCTAClick={() => navigateToTrainers("hero")} />
    </main>
  );
}

const Banner = ({ onCTAClick }) => {
  return (
    <div className="hero mt-2">
      <div className="hero-info">
        <Material.Typography variant="button">
          PARA EMAGRECER DE FORMA SAUDÁVEL
        </Material.Typography>
        <div className="header-title">
          <span className="title-border"></span>
          <Material.Typography variant="h1">
            Alcance seus objetivos de emagrecimento
          </Material.Typography>
        </div>
        <Material.Typography variant="h4" className="mt-1">
          Os melhores personal trainers com atendimento à distância para te
          ajudar a ir para o próximo nível estão aqui
        </Material.Typography>
        <Material.Button
          onClick={onCTAClick}
          className="header-cta cta-btn px-5 py-2 mt-2"
          variant="contained"
          color="secondary"
        >
          Começar meu projeto
        </Material.Button>
        <img src={AppMetrics} className="app-metrics mt-2" />
      </div>
      <img src={FemaleBanner} className="hero-banner" />
    </div>
  );
};

const Benefits = () => {
  const sections = [
    {
      image: Person1,
      title: "Perca gordura todo dia",
      subtitle:
        "Com nossos personal trainers especializados em queima de gordura de forma saudável",
    },
    {
      image: Person2,
      title: "Reduza dores nas articulações",
      subtitle:
        "No treinamento, você ganhará mais força, o que diminuirá o desgaste das articulações",
    },
    {
      image: Person3,
      title: "Acompanhamento personalizado",
      subtitle:
        "Fuja de treinos genéricos de academia, e programas não validados. Tenha a atenção um profissional a um clique de distância",
    },
  ];
  const Benefit = ({ image, title, subtitle }) => {
    return (
      <div className="benefit">
        <img src={image} alt="Benefícios do app" />
        <Material.Typography variant="h3" className="mt-1">
          {title}
        </Material.Typography>
        <Material.Typography variant="subtitle" className="mt-1">
          {subtitle}
        </Material.Typography>
      </div>
    );
  };

  return (
    <div className="benefits mt-2">
      {sections.map((section, i) => (
        <Benefit {...section} key={i} />
      ))}
    </div>
  );
};

const PainAndSolutionArea = () => {
  return (
    <div className="ps mt-5">
      <div className="video-area">
        <iframe
          src={
            "https://www.youtube.com/embed/BJzOI-1hsIQ?si=uW7nZS-gSu4z-_72" +
            "&rel=0"
          }
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="demo-video"
        ></iframe>
      </div>
      <div className="demo-video-description">
        <Material.Typography variant="h3" className="mt-1">
          Você pode ter tentado outras vezes, mas agora é pra valer!
        </Material.Typography>
        <Material.Typography variant="h4" className="mt-1 mb-2">
          Técnicas genéricas de academia não funcionam. Emagrecer de forma
          saudável e sem sofrimento é uma tarefa difícil, e por isso escolhemos
          profissionais a dedo para te ajudar nessa missão
        </Material.Typography>
        <Material.Typography variant="p" className="mt-2">
          Todos os anos, milhões de pessoas vão para a academia buscando perder
          peso. Infelizmente para mais de 66%, este sonho acaba antes de 3 meses
          por não ver resultados.{" "}
          <b>
            Junte-se a mais de 2.000 pessoas que tem alcançado seus objetivos de
            emagrecimento diariamente
          </b>
        </Material.Typography>
      </div>
    </div>
  );
};

const TrainersList = ({ trainers, sectionRef }) => {
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <section className="tl" ref={sectionRef}>
      <Material.Typography variant="h2" className="section-title mb-2">
        Escolha um dos personal trainers disponíveis, e eles te ajudarão a
        atingir seus objetivos
      </Material.Typography>

      <div className="trainers-available">
        {trainers.map((trainer, i) => (
          <TrainerCard {...trainer} key={i} position={i} />
        ))}
      </div>
    </section>
  );
};

const AppDemo = ({ onCTAClick }) => {
  return (
    <div className="app-demo mt-4 mb-6">
      <Material.Typography variant="h2">
        Você alcançará seus objetivos através do aplicativo mais completo do
        momento
      </Material.Typography>
      <Material.Typography variant="h5">
        Tenha o acompanhamento que você merece, e potencialize seus resultados
        com a ajuda de uma tecnologia projetada para te levar para o próximo
        nível
      </Material.Typography>
      <img
        src={AppViews}
        className="app-views mt-3"
        alt="Telas do aplicativo Ginastee"
      />
      <Material.Button
        onClick={onCTAClick}
        className="px-5 py-2 mt-6 cta-btn"
        variant="contained"
        color="secondary"
      >
        Começar minha transformação
      </Material.Button>
    </div>
  );
};

const SuperShortCTA = ({ onCTAClick }) => {
  return (
    <div className="mt-2 scta-area">
      <img src={Semicircle} className="semicircle" />
      <div className="scta px-2">
        <Material.Typography variant="h1">
          Alcance seus objetivos de emagrecimento
        </Material.Typography>

        <Material.Typography variant="h4" className="mt-1">
          Os melhores personal trainers com atendimento à distância para te
          ajudar a ir para o próximo nível estão aqui
        </Material.Typography>

        <Material.Button
          onClick={onCTAClick}
          className="header-cta cta-btn light block-middle px-5 py-2 mt-2"
          variant="contained"
          color="secondary"
        >
          Iniciar o programa de emagrecimento
        </Material.Button>

        <Footer mode="dark" />
      </div>
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="app-demo mt-4">
      <Material.Typography variant="h2">
        Você alcançará seus objetivos através do aplicativo mais completo do
        momento
      </Material.Typography>
      <Material.Typography variant="h5">
        Tenha o acompanhamento que você merece, e potencialize seus resultados
        com a ajuda de uma tecnologia projetada para te levar para o próximo
        nível
      </Material.Typography>
      <img
        src={AppViews}
        alt="Telas do aplicativo Ginastee"
        className="app-views mt-3"
      />
    </div>
  );
};

function trainerFactory(id, image, name, description, url, specialties) {
  return {
    id,
    image,
    name,
    description,
    url,
    specialties,
  };
}
