import React from "react";

import MainComponent from "../../components/MainComponent";
import SignInModal from "../../components/SignInModal";

import api from "../../helpers/api.js";

import * as Material from "@mui/material";

import "./styles.css";

import { useParams } from "react-router-dom";

import SendIcon from "@mui/icons-material/Send";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ReactComponent as VerifiedIcon } from "../../assets/custom-icons/verify.svg";
import { ReactComponent as UploadIcon } from "../../assets/draws/upload.svg";

import logo from "../../assets/logo/M_WHITE_LOGO_FULL.svg";

import CustomerFAQArea from "../../components/CustomerFAQArea";

import Ginalytics from "../../helpers/ginalytics.js";

import DisplayTextWithLineBreaks from '../../components/DisplayTextWithLineBreaks'

export default function Trainer() {
  const [trainerData, setTrainerData] = React.useState();

  const [isVisible, setIsVisible] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [snackMessage, setSnackMessage] = React.useState();

  const [signInModalOpen, setSignInModalOpen] = React.useState(false);

  const registeredPageViewRef = React.useRef();

  let { slug } = useParams();

  const welcomeVideoRef = React.useRef();
  const welcomeVideoTrackProgress = React.useRef({
    1: false,
    25: false,
    50: false,
    80: false,
    99: false,
  });
  const welcomeVideoBtnRef = React.useRef();

  React.useEffect(() => {
    if (loading) handleLoadTrainerData();
  }, [loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setSnackMessage(null);
    }, 5000);
  }, [snackMessage]);

  React.useEffect(() => {
    document
      .querySelector(".trainer-page")
      ?.addEventListener("scroll", listenToScroll);
    return () =>
      document
        .querySelector(".trainer-page")
        ?.removeEventListener("scroll", listenToScroll);
  }, []);

  React.useEffect(() => {
    if (!registeredPageViewRef.current && trainerData) {
      Ginalytics.register(
        "page_view",
        null,
        "hub",
        "trainer_page",
        trainerData?.trainerName
      );
      registeredPageViewRef.current = true;
    }
  }, [trainerData]);

  React.useEffect(() => {
    const params = new URL(document.location).searchParams;
    const startSearchbarOpen = !!params.get("checkoutOpen");
    if (startSearchbarOpen) setSignInModalOpen(true);
  }, []);

  function initiateWelcomeVideo() {
    const video = welcomeVideoRef.current;
    video.addEventListener("timeupdate", function (e) {
      const currentPercentage = (video.currentTime / video.duration) * 100;
      const trackedProgresses = Object.keys(welcomeVideoTrackProgress.current);
      trackedProgresses.forEach((progress) => {
        progress = parseInt(progress);
        if (!alreadyRegistered(progress) && currentPercentage > progress) {
          handleRegisterProgress(progress);
        }
      });
    });

    function alreadyRegistered(progress) {
      return welcomeVideoTrackProgress.current[progress];
    }
    function handleRegisterProgress(progress) {
      welcomeVideoTrackProgress.current[progress] = true;
      Ginalytics.register("welcome_video_progress", null, progress);
    }
  }

  async function handleLoadTrainerData() {
    try {
      const result = await api.get("/hub/trainerData?slug=" + slug);

      setTimeout(() => welcomeVideoRef.current?.load(), 100);

      document.title = result.data.trainerName;

      setTrainerData({ ...result.data });
    } catch (e) {
      console.log(e);
      setSnackMessage("Houve um erro ao carregar a página do personal");
    }

    setLoading(false);
  }

  function handleCheckoutInto(index) {
    Ginalytics.register(
      "checkout_into_plan",
      { planData: trainerData.plans[index] },
      index
    );
    const newPlans = [...trainerData.plans].map((plan, i) => {
      if (index === i) plan.checked = true;
      else plan.checked = false;
      return plan;
    });
    const checkoudData = { ...trainerData, plans: newPlans };
    setTrainerData(checkoudData);
    setSignInModalOpen(true);
  }
  const listenToScroll = () => {
    let startToDisplayOn = 500;
    const winScroll = document.querySelector(".trainer-page").scrollTop;

    if (winScroll < startToDisplayOn) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const availableCycles = {
    WEEKLY: "Semanalmente",
    MONTHLY: "Mensalmente",
    QUARTERLY: "Trimestralmente",
    SEMIANNUALLY: "Semestralmente",
    YEARLY: "Anualmente",
  };

  if (!trainerData?.trainerName && !loading) {
    return <TrainerNotFound />;
  }

  return (
    <main className="trainer-page">
      {!trainerData?.plans ||
        (!trainerData?.plans.length && <NoPlansConfigured />)}
      <MainComponent
        displayHeader={false}
        className="trainer pt-2 pb-8 mb-8 tr"
        snackMessage={snackMessage}
      >
        <img src={logo} className="logo" alt="Foto do personal trainer" />

        <div className="top-info mt-6">
          <div className="avatar-container">
            {loading ? (
              <Material.Skeleton variant="circular" height={180} width={180} />
            ) : (
              <img
                src={trainerData?.avatar}
                className="avatar fade"
                alt="Foto do personal trainer"
              />
            )}
          </div>
          <div className="description-area" style={{ width: "100%" }}>
            {loading ? (
              <>
                <Material.Skeleton
                  className="mt-4"
                  variant="rectangle"
                  width="100%"
                  height={28}
                />
                <Material.Skeleton
                  className="mt-1"
                  variant="rectangle"
                  width="100%"
                  height={18}
                />
                <Material.Skeleton
                  className="mt-1"
                  variant="rectangle"
                  width="40%"
                  height={28}
                />
                <Material.Skeleton
                  className="mt-1"
                  variant="rectangle"
                  width="80%"
                  height={18}
                />
              </>
            ) : (
              <>
                <Material.Typography
                  variant="h2"
                  className="slide-right-fade-small delay-1"
                >
                  {trainerData?.trainerName}
                </Material.Typography>
                <Material.Typography
                  variant="label"
                  className="slide-right-fade-small delay-3"
                >
                  <DisplayTextWithLineBreaks
                    text={trainerData?.longDescription}
                  />
                </Material.Typography>
                {trainerData?.verified && trainerData?.cref ? (
                  <div className="pt-2">
                    <Material.Typography variant="small" color="darkGray">
                      CREF: {trainerData?.cref}
                    </Material.Typography>
                    <br />
                    <br />
                    <VerifiedPersonal
                      name={trainerData?.trainerName.split(" ")[0]}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          <div className="specialties-area">
            {loading ? (
              <Material.Skeleton
                className="mt-4"
                variant="rectangle"
                width="100%"
                height={28}
              />
            ) : (
              <>
                <Material.Typography
                  variant="h3"
                  className=" slide-right-fade-small delay-2"
                >
                  Especialidades
                </Material.Typography>
                <div className="specialties-chips mt-1 slide-right-fade-small delay-4">
                  {trainerData?.specialties?.map((specialty) => (
                    <Material.Chip size="small" label={specialty} />
                  ))}
                </div>

                {trainerData?.plans?.length && (
                  <div className="mt-4 available-plans">
                    <Material.Typography variant="h4">
                      Planos disponíveis:
                    </Material.Typography>
                    <Material.Typography variant="small">
                      (trainamento à distância)
                    </Material.Typography>
                    <div className="plans-list mt-1">
                      {trainerData?.plans.map((plan, i) => (
                        <Material.Chip
                          key={i}
                          className="plan-info mb-1"
                          avatar={
                            <Material.Avatar alt="" src={trainerData.avatar} />
                          }
                          label={`${plan.name} | R$ ${(
                            "" + plan.price.toFixed(2)
                          ).replace(".", ",")} - ${
                            availableCycles[plan.cycle]
                          }`}
                          variant="outlined"
                          deleteIcon={<OpenInNewIcon />}
                          onClick={() => handleCheckoutInto(i)}
                          onDelete={() => handleCheckoutInto(i)}
                        />
                      ))}
                    </div>
                    <Material.Button
                      onClick={() => {
                        setSignInModalOpen(true);
                      }}
                      variant="contained"
                      color="secondary"
                      startIcon={<SendIcon />}
                      className="py-2 px-4 mt-2 cta-button"
                      style={{ width: "100%" }}
                      size="large"
                    >
                      Treinar com {trainerData?.trainerName.split(" ")[0]}
                    </Material.Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="media-area mb-6">
          {loading && (
            <>
              <div className="welcome-video-area">
                <Material.Skeleton
                  className="mt-4"
                  variant="rectangle"
                  width="100%"
                  height={400}
                  sx={{ borderRadius: 6 }}
                />
              </div>
              <div className="mt-4 depoiments">
                <Material.Skeleton
                  className="mt-4"
                  variant="rectangle"
                  width="28%"
                  height={250}
                  className="depoiment"
                  sx={{ boxShadow: "none !important", borderRadius: 4 }}
                />
                <Material.Skeleton
                  className="mt-4"
                  variant="rectangle"
                  width="28%"
                  height={250}
                  className="depoiment"
                  sx={{ boxShadow: "none !important", borderRadius: 4 }}
                />
                <Material.Skeleton
                  className="mt-4"
                  variant="rectangle"
                  width="28%"
                  height={250}
                  className="depoiment"
                  sx={{ boxShadow: "none !important", borderRadius: 4 }}
                />
              </div>
            </>
          )}
          {trainerData?.welcomeVideo && (
            <div className="welcome-video-area fade delay-5">
              <Material.Typography variant="h3" className="mb-1">
                {trainerData?.trainerName.split(" ")[0]} deixou uma mensagem
                para você!
              </Material.Typography>

              <div className="video-container">
                <Material.IconButton
                  ref={welcomeVideoBtnRef}
                  onClick={() => {
                    Ginalytics.register("welcome_video_started", null, "hub");

                    welcomeVideoRef?.current.play();
                  }}
                  className="play-btn"
                >
                  <PlayArrowIcon color="white" />
                </Material.IconButton>
                <button
                  className="toggle-video-btn"
                  onClick={() => {
                    if (welcomeVideoRef.current.paused) {
                      welcomeVideoRef?.current.play();
                    } else {
                      welcomeVideoRef?.current.pause();
                    }
                  }}
                ></button>
                <video
                  onPause={() => {
                    console.log("pause");
                    welcomeVideoBtnRef.current.style.display = "flex";
                  }}
                  onPlay={() => {
                    welcomeVideoBtnRef.current.style.display = "none";
                  }}
                  onLoadedMetadata={initiateWelcomeVideo}
                  controls={true}
                  ref={welcomeVideoRef}
                  className="welcome-video"
                >
                  <source
                    type="video/mp4"
                    src={trainerData?.welcomeVideo + "?a=" + Math.random()}
                  />
                </video>
              </div>
            </div>
          )}
          {!!trainerData?.depoiments?.length && (
            <div className="mt-4 depoiments-container  fade delay-6">
              <Material.Typography variant="h3" className="mb-1">
                Depoimentos
              </Material.Typography>

              <div className="depoiments slide-right-fade-small">
                {trainerData?.depoiments.map((depoiment, i) => (
                  <div className={"depoiment"}>
                    <img
                      alt="Depoimento de alunos"
                      src={depoiment}
                      sizes="(max-width: 350px) 100vw, 50vw"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <CustomerFAQArea />
      </MainComponent>

      {trainerData && isVisible && <FloatingCard info={trainerData} />}

      {!loading && (
        <Material.Button
          onClick={() => setSignInModalOpen(true)}
          variant="contained"
          color="secondary"
          startIcon={<SendIcon />}
          className="py-2 px-4 mt-2 cta-button pulse-animation"
          size="large"
        >
          Treinar com {trainerData?.trainerName.split(" ")[0]}
        </Material.Button>
      )}

      <SignInModal
        trainerName={trainerData?.trainerName.split(" ")[0]}
        onOpen={() => setSignInModalOpen(true)}
        plans={trainerData?.plans}
        slug={slug}
        open={signInModalOpen}
        onClose={() => setSignInModalOpen(false)}
      />
    </main>
  );
}



function VerifiedPersonal({ name }) {
  return (
    <div className="verified-card">
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <VerifiedIcon />
        <Material.Typography variant="h4">
          Personal verificado
        </Material.Typography>
      </div>
      <Material.Typography variant="label" style={{ color: "gray" }}>
        Nossa equipe verificou a identidade de {name}
      </Material.Typography>
    </div>
  );
}

function FloatingCard({ info }) {
  return (
    <div className="px-1 py-1 floating-card fade ">
      <img
        src={info.avatar}
        alt="Foto do personal trainer"
        sizes="(max-width: 100px) 100vw, 50vw"
      />
      <div>
        <Material.Typography variant="h4">
          {info.trainerName}
        </Material.Typography>
        <Material.Typography variant="small">
          {info.shortDescription}
        </Material.Typography>
      </div>
    </div>
  );
}

function TrainerNotFound() {
  return (
    <MainComponent className="trainer pt-2 pb-8 mb-8 tr">
      <div className="pt-6 pt-not-found">
        <UploadIcon style={{ maxWidth: 160, height: "auto" }} />
        <Material.Typography variant="h3">
          Ops! Não encontramos este personal trainer
        </Material.Typography>
        <Material.Typography variant="h4">
          O link desta página pode ter sido desabilitado, ou enviado para você
          faltando uma parte
        </Material.Typography>
      </div>
    </MainComponent>
  );
}

function NoPlansConfigured() {
  return (
    <div className="pt-not-found plans-not-set">
      <UploadIcon style={{ maxWidth: 160, height: "auto" }} />

      <Material.Typography variant="h3">
        Esta página está incompleta
      </Material.Typography>
      <Material.Typography
        style={{ maxWidth: 600 }}
        variant="h4"
        className="mt-1"
      >
        Os planos e métodos de pagamento ainda não foram configurados.
      </Material.Typography>
      <Material.Typography
        style={{ maxWidth: 600 }}
        variant="h4"
        className="mt-1"
      >
        Se você é o personal trainer responsável por esta página, clique abaixo
        para finalizar a configuração
      </Material.Typography>
      <Material.Button
        variant="contained"
        color="secondary"
        startIcon={<SendIcon />}
        className="py-2 px-4 mt-2"
        size="large"
        onClick={() => window.open("https://adm.ginastee.com/planos")}
      >
        Finalizar configuração
      </Material.Button>
    </div>
  );
}
