import * as Material from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./styles.css";

import Ginalytics from "../../helpers/ginalytics.js";

export default function CustomerFAQArea({ mode = "light", trainerId }) {
  const QA = [
    {
      question: "Como saber se um personal trainer é confiável?",
      answer:
        "A equipe Ginastee faz recorrentemente verificações em todos os personal trainers, verificando a identidade do personal. Tomamos todas as medidas para garantir que você tenha o treinamento que está contratando",
    },
    /*{
      question: "Os personal trainers são certificados?",
      answer:
        "Sim! Nossa equipe valida o registro de cada personal no Conselho Regional de Educação Física, garantindo que aquele profissional tem o conhecimento para te ajudar.",
    },*/
    {
      question:
        "E se o personal não me atender, como posso pedir o dinheiro de volta?",
      answer:
        "Ao escolher um personal, nós entramos em contato imediatamente, informando que você está aguardando o acompanhamento. Caso seu personal não inicie seu atendimento em até 48 horas, você pode entrar em contato conosco pelo WhatsApp 31971583279, e pedir o estorno da assinatura",
    },
    {
      question: "Como funciona o acompanhamento?",
      answer:
        "Ao optar por treinar com um de nossos personal trainers, você irá receber acesso ao aplicativo Ginastee, e seu treinador irá acompanhar você por lá. É com o aplicativo Ginastee que você irá ter sua ficha de treinos personalizada, e medir seu progresso.",
    },
    {
      question: "Por onde eu irei receber os treinos?",
      answer:
        "Assim que o pagamento for confirmado, você receberá o acesso ao aplicativo Ginastee, onde irá ter acesso aos seus treinos, e outras ferramentas para te ajudar a ter sucesso na sua jornada fitness.",
    },
    {
      question: "Sou personal, como faço para participar do Ginastee?",
      answer:
        "Se você é um personal trainer, e deseja fazer parte desta história, basta se cadastrar em https://adm.ginastee.com, e criar o seu perfil de personal. Iremos verificar sua identidade e você poderá oferecer a experiência Ginastee para seus alunos.",
    },
  ];

  const parseLinks = (text) => {
    const linkRegex = /(\bhttps?:\/\/\S+\b)/gi; // Regular expression to match URLs
    return text.split(linkRegex).map((part, index) => {
      if (part.match(linkRegex)) {
        return (
          <a
            className="url"
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  };

  return (
    <div className={"faq-mode-" + mode}>
      <div className="faq-title mb-3">
        <Material.Typography variant="h2">
          Perguntas mais frequentes
        </Material.Typography>
      </div>

      {QA.map((q, i) => (
        <Material.Accordion key={i} className="mb-1 qa-box">
          <Material.AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={q.question}
          >
            <Material.Typography variant="h4">
              {q.question}{" "}
            </Material.Typography>
          </Material.AccordionSummary>
          <Material.AccordionDetails>
            <Material.Typography variant="h5">
              {parseLinks(q.answer)}
            </Material.Typography>
          </Material.AccordionDetails>
        </Material.Accordion>
      ))}
      <div className="faq-title mb-3 mt-4">
        <Material.Typography variant="h3" style={{ color: "white" }}>
          Ficou com alguma dúvida? 
        </Material.Typography>
        <Material.Typography variant="h4" style={{ color: "white" }}>
          Clique no botão abaixo e fale conosco
        </Material.Typography>
        <Material.Button
          variant="contained"
          color="secondary"
          style={{ width: "fit-content" }}
          className="py-2 px-4"
          onClick={() => {
            Ginalytics.register(
              "contact",
              {
                placement: "faq_area",
              },
              "hub",
              window.location.pathname
            );
            window.open(
              "https://api.whatsapp.com/send/?phone=5531971583279&text&type=phone_number&app_absent=0"
            );
          }}
        >
          Fale conosco
        </Material.Button>
      </div>
    </div>
  );
}
