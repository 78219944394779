import React, { useState, useEffect, useRef, useMemo } from "react";

import logo from "../../assets/logo/M_WHITE_LOGO_FULL.svg";

import * as Mui from "@mui/material";

import "./styles.css";

import LoginIcon from "@mui/icons-material/Login";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlaceIcon from "@mui/icons-material/Place";
import CheckIcon from "@mui/icons-material/Check";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PixIcon from "@mui/icons-material/Pix";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReplayIcon from "@mui/icons-material/Replay";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";

import api from "../../helpers/api.js";
import axios from "axios";

import validator from "validator";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Ginalytics from "../../helpers/ginalytics.js";

import phoneGif from "../../assets/animations/phone.gif";

export default function SignInModal({
  onOpen,
  open,
  onClose,
  slug,
  plans,
  trainerName = "",
}) {
  const [height, setHeight] = useState(320);

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const [mode, setMode] = useState("LOGIN");

  const steps = [
    "SIGNIN_STEP_1",
    "SIGNIN_STEP_2",
    "LOGIN",
    "CHECK_CODE",
    "CHECKOUT",
    "PAYMENT_CONFIRMED",
  ];
  const [step, setStep] = useState(steps[0]);

  const phoneValueRef = useRef("");

  const nameRef = useRef("");
  const emailRef = useRef("");
  const optInRef = useRef(true);

  const cepValueRef = useRef();
  const addressNumberValueRef = useRef();
  const addressInfoValueRef = useRef();
  const birthdayValueRef = useRef();
  const genderValueRef = useRef("FEMALE");

  const paymentInfoRef = useRef({});

  const cpfValueRef = useRef();

  const containerRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      calculateNewHeight();
    }, 150);
  }, [error, loading, success, step, open]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
        setTimeout(() => {
          calculateNewHeight();
        }, 150);
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (open) {
      Ginalytics.register("initiate_signin");
    }
  }, [open]);

  function calculateNewHeight() {
    const newHeight = containerRef.current?.scrollHeight;

    if (newHeight !== height && newHeight) {
      setHeight(newHeight + 20);
    }
  }

  async function handleSendSmsCode() {
    setLoading(true);
    try {
      const result = await api.post("auth/sendNewSMSCode", {
        phone: "+55" + phoneValueRef.current,
      });
      console.log(result.data);

      if (result.data.success) {
        setMode("LOGIN");
        setStep(steps[3]);
        setLoading(false);
      }

      if (result.data.error === "no_user_found") {
        setMode("SIGNIN");
        setStep(steps[3]);
        setLoading(false);
      }
    } catch (e) {
      Ginalytics.registerError("phone_login", {
        phone: phoneValueRef.current,
        error,
      });
      setError("Houve um erro inesperado ao fazer login");
    }
  }

  async function paymentConfirmed() {
    console.log(paymentInfoRef);
    setStep(steps[5]);
    onOpen();
  }

  return (
    <Mui.Modal open={open} onClose={onClose} className="modal-bd">
      <div
        className="modal-container pb-2"
        style={{ height: height, transition: "all 0.5s" }}
      >
        <div ref={containerRef}>
          <div
            ref={containerRef}
            className="px-3 py-1 modal-header"
            style={{ backgroundColor: "#ffffff4f" }}
          >
            <Mui.Typography variant="h4">
              Entre para treinar com {trainerName}
            </Mui.Typography>
            <Mui.IconButton onClick={onClose}>
              <CloseIcon />
            </Mui.IconButton>
          </div>
          <Mui.Divider />

          <Mui.LinearProgress
            variant="determinate"
            color="secondary"
            value={((steps.indexOf(step) + 1) / steps.length) * 100}
          />

          {
            /**
             *   const steps = [
    "SIGNIN_STEP_1",
    "SIGNIN_STEP_2",
    "LOGIN",
    "CHECK_CODE",
    "CHECKOUT",
    "PAYMENT_CONFIRMED",
  ];
             * 
             */
            {
              LOGIN: (
                <LoginSection
                  loading={loading}
                  onBtnPress={(shouldSendSms) => {
                    if (shouldSendSms) handleSendSmsCode();
                    else {
                      setStep(steps[3]);
                    }
                  }}
                  setLoading={setLoading}
                  phoneValueRef={phoneValueRef}
                  handleResize={calculateNewHeight}
                  onReturn={() => setStep(steps[1])}
                  cepRef={cepValueRef}
                  addressNumberRef={addressNumberValueRef}
                  birthdayRef={birthdayValueRef}
                  genderRef={genderValueRef}
                  addressInfoRef={addressInfoValueRef}
                  nameRef={nameRef}
                  slug={slug}
                  emailRef={emailRef}
                  optInRef={optInRef}
                />
              ),
              SIGNIN_STEP_1: (
                <NameAndEmailStep
                  onGoToNext={() => setStep(steps[1])}
                  nameRef={nameRef}
                  emailRef={emailRef}
                  phoneRef={phoneValueRef}
                  optInRef={optInRef}
                  slug={slug}
                />
              ),
              CHECK_CODE: (
                <ConfirmPhoneSection
                  onBtnPress={() => {
                    setStep(steps[4]);
                  }}
                  onReturn={() => setStep(steps[2])}
                  setError={setError}
                  setLoading={setLoading}
                  phoneValueRef={phoneValueRef}
                />
              ),
              SIGNIN_STEP_2: (
                <AddressAndComplementaryStep
                  onGoToNext={() => setStep(steps[2])}
                  onReturn={() => setStep(steps[0])}
                  cepRef={cepValueRef}
                  addressNumberRef={addressNumberValueRef}
                  birthdayRef={birthdayValueRef}
                  genderRef={genderValueRef}
                  addressInfoRef={addressInfoValueRef}
                  updateModalHeight={calculateNewHeight}
                />
              ),
              CHECKOUT: (
                <CheckoutStep
                  slug={slug}
                  cepRef={cepValueRef}
                  onGoNext={paymentConfirmed}
                  addressNumberRef={addressNumberValueRef}
                  addressInfoRef={addressInfoValueRef}
                  availablePlans={plans}
                  resizeModal={calculateNewHeight}
                  paymentInfoRef={paymentInfoRef}
                  cpfRef={cpfValueRef}
                />
              ),
              PAYMENT_CONFIRMED: <PaymentConfirmed />,
            }[step]
          }

          {error && (
            <Mui.Alert
              severity="error"
              className="mx-3 mb-2"
              style={{ borderRadius: 18 }}
            >
              {error}
            </Mui.Alert>
          )}
          {success && (
            <Mui.Alert
              severity="success"
              className="mx-3 mb-2"
              style={{ borderRadius: 18 }}
            >
              {success}
            </Mui.Alert>
          )}
        </div>
      </div>
    </Mui.Modal>
  );
}

function getSource() {
  const params = new URL(document.location).searchParams;
  return params.get("user_src");
}

const LoginSection = ({
  loading,
  setLoading,
  phoneValueRef,
  onBtnPress,
  onReturn,
  handleResize,
  cepRef,
  nameRef,
  addressNumberRef,
  birthdayRef,
  genderRef,
  addressInfoRef,
  slug,
  emailRef,
  optInRef,
}) => {
  const [phone, setPhone] = useState(phoneValueRef.current || "");
  const [error, setError] = useState("");

  const userSource = getSource();

  useEffect(handleResize, [error]);

  function handleInputPhone(v) {
    const value = v.target.value.replace(/[^0-9]/g, "");
    phoneValueRef.current = value;
    setPhone(value);
  }

  async function handleSubmit() {
    if (phoneValueRef.current?.length !== 11) {
      Ginalytics.registerError("incorrect_phone_in_login", {
        phone: phoneValueRef.current,
        error,
      });
      setError("Insira o número de celular com DDD (totalizando 11 dígitos)");
      return;
    }

    const result = await createUserAccount();

    let shouldSendConfirmationCode = false;

    if (result?.error === "USER_ALREADY_EXISTS") {
      shouldSendConfirmationCode = true;
    }

    console.log('Should send SMS', shouldSendConfirmationCode)

    onBtnPress(shouldSendConfirmationCode);

    async function createUserAccount() {
      const body = {
        name: nameRef.current,
        phone: "+55" + phone,
        email: emailRef.current,
        opt_in_to_sms: optInRef.optIn,
        slug,
        src: userSource || null,
      };

      try {
        const result = await api.post("auth/preSignin", body);
        console.log(result.data);
        Ginalytics.register(
          "pre_signin",
          { result: result.data },
          body.phone,
          body.email,
          body.name
        );
        return result.data;
      } catch (e) {
        Ginalytics.registerError("pre_signin", { body, error: e });
        console.log(e);
      }
    }
  }

  return (
    <div className="px-5 pt-4 pb-2 fields-area">
      <img
        src={phoneGif}
        style={{
          width: "118px",
          margin: "0 auto",
        }}
      />

      <Mui.Typography variant="h3" className="txm">
        Qual celular você usará para entrar no aplicativo?
      </Mui.Typography>
      <Mui.Typography variant="h5" className="mb-2 txm">
        Seu número de celular será usado para você entrar no app e acessar os
        treinos
      </Mui.Typography>

      {error && <Mui.Alert severity="error">{error}</Mui.Alert>}

      <Mui.TextField
        label="Celular"
        color="secondary"
        value={phone}
        onInput={handleInputPhone}
        variant="filled"
        disabled={loading}
        fullWidth
        autoComplete="tel-national"
        InputProps={{
          startAdornment: (
            <Mui.InputAdornment position="start">
              <LocalPhoneIcon />
            </Mui.InputAdornment>
          ),
        }}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        helperText="Insira seu celular com DDD"
      />

      <Mui.Button
        variant="contained"
        disabled={loading}
        color="primary"
        onClick={handleSubmit}
        startIcon={loading ? <Mui.CircularProgress size={18} /> : <LoginIcon />}
        className="py-2 px-4 mt-2 main-modal-btn"
      >
        Enviar código via SMS
      </Mui.Button>
      <Mui.Button
        variant="text"
        color="primary"
        onClick={onReturn}
        className="px-2 py-2 main-modal-btn"
      >
        Voltar
      </Mui.Button>
    </div>
  );
};
const NameAndEmailStep = ({
  optInRef,
  emailRef,
  nameRef,
  phoneRef,
  onGoToNext,
  onReturn,
  slug,
}) => {
  const [email, setEmail] = useState(emailRef.current || "");

  const [name, setName] = useState(nameRef.current || "");

  const [optIn, setOptIn] = useState(optInRef.current);

  const [termsAgree, setTermsAgree] = useState(false);

  const [formErrors, setFormErrors] = useState(false);

  const [loading, setLoading] = useState(false);

  const inputNameRef = useRef();
  const inputEmailRef = useRef();

  useEffect(() => {
    if (formErrors?.name) {
      setTimeout(() => {
        inputNameRef.current?.focus();
      }, 40);
    }
    if (formErrors?.email) {
      setTimeout(() => {
        inputEmailRef.current?.focus();
      }, 40);
    }
  }, [formErrors]);

  function handleInputEmail(v) {
    const value = v.target.value.replace(/[^a-zA-Z0-9@._-]/g, "").trim();
    emailRef.current = value;
    setEmail(value);
    if (formErrors?.email) validateForm();
  }
  function handleInputName(v) {
    const value = capitalizeName();
    nameRef.current = value;
    setName(value);
    if (formErrors?.name) validateForm();

    function capitalizeName() {
      let value = v.target.value;
      value = value.split(" ");
      return value
        .map((s) => {
          return `${s.slice(0, 1).toUpperCase()}${s
            .substring(1)
            .toLowerCase()}`;
        })
        .join(" ");
    }
  }
  async function handleSubmit() {
    const hasFormErrors = validateForm();

    if (hasFormErrors?.name || hasFormErrors?.email) return;

    Ginalytics.register("filled_name_and_email", {}, email, name);

    onGoToNext();
  }

  function validateForm() {
    const errors = {
      email: !validator.isEmail(email),
      name: name.trim().split(" ").length < 2,
    };
    setFormErrors({ ...errors });
    return errors;
  }

  return (
    <div className="px-5 pt-4 pb-2 fields-area">
      <Mui.Typography variant="h3" className="mb-1 txm slide-bottom-fade-small">
        Você está prestes a se juntar a outras 2.000 pessoas
      </Mui.Typography>
      <Mui.Typography variant="h4" className="mb-2 txm slide-bottom-fade-small">
        Nos ajude a identificar quem é você
      </Mui.Typography>

      <Mui.TextField
        label="Nome completo"
        color="secondary"
        error={formErrors?.name}
        value={name}
        onInput={handleInputName}
        variant="filled"
        autoComplete="name"
        fullWidth
        inputProps={{
          ref: inputNameRef,
        }}
        helperText={
          formErrors?.name
            ? "Você precisa informar seu nome completo para identificação"
            : "Insira seu nome para te identificarmos"
        }
      />

      <Mui.TextField
        error={formErrors?.email}
        label="Email"
        color="secondary"
        placeholder="seu@email.com"
        value={email}
        onInput={handleInputEmail}
        variant="filled"
        fullWidth
        autoComplete="email"
        InputProps={{
          startAdornment: (
            <Mui.InputAdornment position="start">
              <EmailIcon />
            </Mui.InputAdornment>
          ),
          ref: inputEmailRef,
        }}
        inputProps={{
          ref: inputEmailRef,
        }}
        helperText={formErrors?.email ? "Insira um email válido" : "Seu email"}
      />

      <Mui.FormControlLabel
        sx={{ userSelect: "none" }}
        control={
          <Mui.Checkbox
            checked={optIn}
            onChange={(v) => {
              const value = v.target.checked;
              optInRef.current = value;
              setOptIn(value);
            }}
            checkedIcon={<CheckIcon color="secondary" />}
          />
        }
        label="Receber notificações via SMS"
      />

      <Mui.Typography variant="label">
        Ao continuar, você aceita os{" "}
        <a
          target="_blank"
          href="https://ginastee.com/termos-de-uso"
          className="link"
        >
          Termos de uso e política de privacidade
        </a>
      </Mui.Typography>

      <Mui.Alert severity="info" className="mt-1">
        <Mui.Typography variant="label">
          Não se preocupe! Nós não compartilhamos seus dados com ninguém
        </Mui.Typography>
      </Mui.Alert>

      <Mui.Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
        startIcon={<ArrowForwardIosIcon />}
        className="py-2 px-4 mt-2 main-modal-btn"
      >
        Salvar identificação
      </Mui.Button>
    </div>
  );
};
const ConfirmPhoneSection = ({ phoneValueRef, onBtnPress, setError }) => {
  const inputsRef = useRef(["", "", "", "", "", ""]);
  const inputsValues = useRef([]);

  const [loading, setLoading] = useState(false);

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
  }, [timer]);

  function handleCodeInput(index, v) {
    const value = v.replace(/[^0-9]/g, "");
    if (!value) {
      inputsRef.current[index].value = "";
      return;
    }
    inputsValues.current[index] = value;
    if (index !== inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    } else {
      if (!timer) handleConfirmCode();
    }
  }

  async function handleConfirmCode() {
    const code = inputsValues.current.join("");
    if (code.length !== 6) return;
    try {
      setLoading(true);
      const result = await api.post("auth/verifySMSCode", {
        phone: "+55" + phoneValueRef.current,
        code,
      });
      console.log(result.data);
      if (result.data.result) {
        Ginalytics.register(
          "phone_confirmed",
          { result: result.data },
          "+55" + phoneValueRef.current
        );

        localStorage.setItem("token", result.data.token);
        api.configure(result.data.token);
        onBtnPress();
        return;
      }
    } catch (e) {
      Ginalytics.registerError("confirm_phone", {
        phone: "+55" + phoneValueRef.current,
        code,
        error: e,
      });

      console.log(e);
    }

    inputsRef.current.forEach((_, i) => (inputsRef.current[i].value = ""));
    inputsValues.current = inputsValues.current.map((i) => "");

    inputsRef.current[0].focus();

    setLoading(false);
    setError("Código inválido, tente novamente em 60s");
    setTimer(60);
  }

  function getDelayForInput(i) {
    if (i === 0 || i === 5) return 5;
    if (i === 1 || i === 4) return 3;
    if (i === 2 || i === 3) return 1;
  }

  return (
    <div className="px-5 pt-4 pb-2 fields-area">
      <img
        src={phoneGif}
        style={{
          width: "118px",
          margin: "0 auto",
        }}
      />

      <Mui.Typography variant="h3" className="txm slide-bottom-fade-small">
        Insira o o código enviado via SMS para seu celular
      </Mui.Typography>

      <Mui.Typography variant="h4" className="txm mb-2 slide-bottom-fade-small">
        O código pode demorar cerca até 1 minuto para chegar
      </Mui.Typography>

      <div className="check-code-inputs mb-5">
        {inputsRef.current.map((_, index) => (
          <Mui.TextField
            className={
              "slide-bottom-fade-small delay-" + getDelayForInput(index)
            }
            color="secondary"
            inputProps={{
              ref: (r) => (inputsRef.current[index] = r),
              inputMode: "numeric",
              pattern: "[0-9]*",
              style: {
                textAlign: "center",
              },
              autocomplete: "off",
            }}
            onFocus={
              () =>
                (inputsRef.current[index].value =
                  "") /*inputsRef.curent[index].value = ""*/
            }
            onInput={(v) => handleCodeInput(index, v.target.value)}
          />
        ))}
      </div>

      {timer > 0 && (
        <Mui.Typography variant="h5" className="txm slide-bottom-fade-small">
          Tentar novamente em: {timer}s
        </Mui.Typography>
      )}

      <Mui.Button
        variant="contained"
        disabled={loading || timer > 0}
        color="primary"
        onClick={handleConfirmCode}
        startIcon={loading ? <Mui.CircularProgress size={18} /> : <LoginIcon />}
        className="py-2 px-4  main-modal-btn"
      >
        {!loading && "Confirmar código"}
      </Mui.Button>
    </div>
  );
};
const AddressAndComplementaryStep = ({
  cepRef,
  addressNumberRef,
  birthdayRef,
  genderRef,
  addressInfoRef,
  onGoToNext,
  onReturn,
  updateModalHeight,
}) => {
  const [cep, setCep] = useState(cepRef.current || "");
  const [addressNumber, setAddressNumber] = useState(
    addressNumberRef.current || ""
  );
  const [birthday, setBirthDay] = useState(birthdayRef.current || "");
  const [gender, setGender] = useState(genderRef.current || "");

  const [loadedAddress, setLoadedAddress] = useState(
    addressInfoRef.current || {}
  );

  const [formErrors, setFormErrors] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);

  const inputsRef = useRef({
    cep: {
      element: null,
    },
    addressNumber: {
      element: null,
    },
    birthday: {
      element: null,
    },
    gender: {
      element: null,
    },
  });

  useEffect(() => {
    let hasError = false;
    Object.keys(inputsRef.current).forEach((inp) => {
      if (formErrors && formErrors[inp] && !hasError) {
        hasError = true;

        setTimeout(() => {
          inputsRef.current[inp].element.focus();
        }, 40);
      }
    });
  }, [formErrors]);

  useEffect(updateModalHeight, [loadedAddress]);

  async function handleCEPInput(v) {
    const value = v.target.value.trim().replace(/[^0-9]/g, "");
    if (value.length > 8) return;
    cepRef.current = value;
    setCep(value);

    if (formErrors?.cep) validateForm();

    if (value.length === 8) await searchForCEP();

    async function searchForCEP() {
      try {
        setAddressLoading(true);
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        let result = await fetch(
          "https://viacep.com.br/ws/" + value + "/json/",
          requestOptions
        );
        result = await result.json();

        if (!result.cep) {
          addressInfoRef.current = null;
          setFormErrors({ ...formErrors, cep: true });
          setLoadedAddress(null);
        } else {
          addressInfoRef.current = { ...result };
          inputsRef.current.addressNumber.element.focus();
          setLoadedAddress({ ...result });
          setFormErrors({ ...formErrors, cep: false });
        }
      } catch (e) {
        Ginalytics.registerError("fetch_address", {
          address: addressInfoRef.current,
          error: e,
        });
        addressInfoRef.current = null;
        setLoadedAddress(null);
        setFormErrors({ ...formErrors, cep: true });
      }

      setAddressLoading(false);
    }
  }
  function handleNumberInput(v) {
    const value = v.target.value.trim().replace(/[^0-9]/g, "");
    addressNumberRef.current = value;
    setAddressNumber(value);

    if (formErrors?.addressNumber) validateForm();
  }

  function handleGenderInput(v) {
    const newV = v.target.value;
    genderRef.current = newV;
    setGender(newV);
  }

  function handleSubmit() {
    const hasFormErrors = validateForm();

    if (!hasFormErrors.cep && !hasFormErrors.addressNumber) {
      Ginalytics.register("submit_address_step");

      onGoToNext();
    }
  }

  function validateForm() {
    const errors = {
      cep: cep.length !== 8 || !loadedAddress?.cep,
      addressNumber: addressNumber.length === 0,
    };
    setFormErrors({ ...errors });
    return errors;
  }

  return (
    <div className="px-5 pt-4 pb-2 fields-area">
      <Mui.Typography variant="h3" className="slide-bottom-fade-small txm">
        De onde você é?
      </Mui.Typography>

      <Mui.Typography variant="h4" className="slide-bottom-fade-small mb-2 txm">
        Isso nos ajuda a te fazer boas recomendações e personalizar o aplicativo
        para você!
      </Mui.Typography>

      <Mui.TextField
        autoFocus
        error={formErrors?.cep}
        label="CEP"
        color="secondary"
        autoComplete="postal-code"
        placeholder="Cep do seu endereço"
        value={cep}
        onInput={handleCEPInput}
        variant="filled"
        fullWidth
        InputProps={{
          startAdornment: (
            <Mui.InputAdornment position="start">
              <PlaceIcon />
            </Mui.InputAdornment>
          ),
          endAdornment: addressLoading && (
            <Mui.InputAdornment position="end">
              <Mui.CircularProgress size={16} color="secondary" />
            </Mui.InputAdornment>
          ),
        }}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          ref: (refV) => (inputsRef.current.cep.element = refV),
        }}
        helperText="Insira o CEP da sua residência"
      />

      {loadedAddress?.cep && cep.length === 8 && (
        <Mui.Typography
          variant="h5"
          className="slide-bottom-fade-small txm mb-1 px-2 py-1  address-box"
        >
          Bairro {loadedAddress.bairro}, {loadedAddress.localidade},{" "}
          {loadedAddress.logradouro} - {loadedAddress.uf}
        </Mui.Typography>
      )}

      <Mui.TextField
        error={formErrors?.addressNumber}
        label="Número do endereço"
        color="secondary"
        value={addressNumber}
        onInput={handleNumberInput}
        variant="filled"
        fullWidth
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          ref: (refV) => (inputsRef.current.addressNumber.element = refV),
        }}
        helperText="Insira o número da sua residência"
      />

      {/* <Mui.FormControl className="mt-2 gender-radios">
        <Mui.Typography variant="h5">Seu sexo biológico</Mui.Typography>
        <Mui.RadioGroup value={gender} onChange={handleGenderInput}>
          <Mui.FormControlLabel
            value="FEMALE"
            control={<Mui.Radio color="secondary" />}
            label="Feminino"
          />
          <Mui.FormControlLabel
            value="MALE"
            control={<Mui.Radio color="secondary" />}
            label="Masculino"
          />
        </Mui.RadioGroup>
      </Mui.FormControl>

      <Mui.Typography variant="h5" className="mt-2">Data de nascimento</Mui.Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker color="secondary" />
      </LocalizationProvider>*/}

      <Mui.Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        startIcon={<ArrowForwardIosIcon />}
        className="py-2 px-4 mt-2 main-modal-btn"
      >
        Salvar endereço
      </Mui.Button>
      <Mui.Button
        variant="text"
        color="primary"
        onClick={onReturn}
        className="px-2 py-2 main-modal-btn"
      >
        Voltar
      </Mui.Button>
    </div>
  );
};
const CheckoutStep = ({
  cepRef,
  slug,
  addressNumberRef,
  availablePlans,
  cpfRef,
  addressInfoRef,
  resizeModal,
  onGoNext,
  paymentInfoRef,
}) => {
  const [plans, setPlans] = useState([...availablePlans]);

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [cpf, setCpf] = useState(cpfRef.current || "");

  const [loading, setLoading] = useState(false);

  const [pixImage, setPixImage] = useState(null);

  const [paymentStatus, setPaymentStatus] = useState("");

  const subscriptionIdRef = useRef();
  const inputsRef = useRef();

  const viewedCheckoutRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setTimeout(resizeModal, 150);
    }, 6000);
    setTimeout(resizeModal, 150);
  }, [error, pixImage, success]);

  useEffect(() => {
    if (!viewedCheckoutRef.current) {
      viewedCheckoutRef.current = true;
      Ginalytics.register("checkout_viewed");

      if (plans && plans.length === 1) {
        selectPlan(0);
      }
    }
  }, []);

  const availableCycles = {
    WEEKLY: "Semanalmente",
    MONTHLY: "Mensalmente",
    QUARTERLY: "Trimestralmente",
    SEMIANNUALLY: "Semestralmente",
    YEARLY: "Anualmente",
  };

  const selectedPlan = plans.filter((p) => p.checked)[0];
  const hasPlanSelected = !!selectedPlan;
  const methodChecked =
    !!selectedPlan &&
    (selectedPlan.currentMethod ||
      (selectedPlan.paymentMethods.includes("CREDIT_CARD")
        ? "CREDIT_CARD"
        : selectedPlan.paymentMethods[0]));

  paymentInfoRef.current.plan = selectedPlan;
  paymentInfoRef.current.method = methodChecked;

  function handleCPFInput(v) {
    const value = removeNonNumbersFrom(v.target.value);
    cpfRef.current = value;
    setCpf(value);
  }
  async function handleSubmit() {
    const hasFormErrors = validateForm();

    if (hasFormErrors) {
      setError(hasFormErrors);
      Ginalytics.registerError("checkout_sumbit", { errors: hasFormErrors });
      return;
    }

    Ginalytics.register("checkout_sumbit");

    setLoading(true);

    const payload = {
      planId: selectedPlan.id,
      payment_method: methodChecked,
      card: paymentInfoRef,
    };

    if (methodChecked === "CREDIT_CARD") {
      const expiration = paymentInfoRef.current.cardExpiration;
      payload.expiry_year = expiration.split("/")[1].slice(-2);
      payload.expiry_month = expiration.split("/")[0];
      payload.number = paymentInfoRef.current.cardNumber;
      payload.ccv = paymentInfoRef.current.cardCcv;
    }

    console.log(payload);

    try {
      await updateUserInfo();

      const result = await api.post("subscriptions", payload);
      console.log(result.data);

      if (methodChecked === "PIX") {
        if (!result.data.success) {
          Ginalytics.registerError(
            "checkout_sumbit_pix",
            { error: result.data, cpf },
            "hub"
          );

          setError(
            "Houve um erro ao gerar o QR Code para pagamento via PIX, tente novamente mais tarde"
          );
        } else {
          Ginalytics.register("checkout_pix_generated");

          const pixData = JSON.parse(result.data.pix_data);
          console.log(pixData);
          window.addEventListener("focus", getSubscriptionStatus);
          subscriptionIdRef.current = result.data.subcription_id;
          setPixImage({
            image: pixData.encodedImage,
            url: pixData.payload,
          });
        }
      } else {
        if (result.data.success) {
          onGoNext();
        }
      }
    } catch (e) {
      Ginalytics.registerError(
        "checkout_sumbit_request_error",
        { error: e, cpf },
        "hub"
      );

      setError(
        "Houve um erro ao processar o pagamento, tente novamente mais tarde"
      );
      console.log(e);
    }

    setLoading(false);

    async function updateUserInfo() {
      const updates = {};

      updates.document = cpf;

      updates.gym_id = slug;

      if (cepRef.current) {
        updates.address_number = addressNumberRef.current;
        updates.postal_code = cepRef.current;
      }

      const src = getSource();

      if (src) {
        updates.src = src;
      }

      try {
        const result = await api.patch("updateUserData", {
          updates,
        });
      } catch (e) {
        Ginalytics.registerError("update_user_info", { error: e }, "hub");

        console.log(e);
        return false;
      }
    }
  }
  async function getSubscriptionStatus() {
    try {
      setLoading(true);

      const result = await api.get(
        "subscriptions?id=" + subscriptionIdRef.current
      );
      console.log(result.data);

      if (result.data.status === "PENDING") {
        setPaymentStatus("PENDING");
      } else {
        onGoNext();
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  function validateForm() {
    if (!isCPFValid(cpf)) {
      return "Digite um CPF válido";
    }

    if (methodChecked === "PIX") return false;

    const cardData = paymentInfoRef.current;

    if (!validator.isCreditCard("" + cardData.cardNumber)) {
      return "Insira um cartão de crédito válido";
    }

    if (cardData.cardCcv?.length !== 3) {
      return "Insira o código de segurança do seu cartão";
    }

    if (!isCardExpirationValid("" + cardData.cardExpiration)) {
      return "Digite uma data de validade do cartão válida";
    }

    return false;
  }

  async function handleCopyQRCode() {
    Ginalytics.register("pix_copied");

    setSuccess(
      "PIX copia e cola copiado com sucesso. Insira o código copiado em seu banco para realizar o pagamento via PIX"
    );
    await navigator.clipboard.writeText(pixImage.url);
  }

  function selectPlan(index) {
    Ginalytics.register("selected_plan", {
      planIndex: index,
      plan: plans[index],
    });

    const newPlans = [...plans].map((plan, i) => {
      if (index === i) {
        plan.checked = true;
      } else plan.checked = false;
      return plan;
    });
    setPlans([...newPlans]);
    setTimeout(resizeModal, 100);
  }

  function selectPaymentMethod(method) {
    const newPlans = [...plans].map((plan) => {
      if (plan.checked) {
        plan.currentMethod = method;
      }
      return plan;
    });

    console.log(method);

    Ginalytics.register("selected_method", null, method);

    setPlans([...newPlans]);
    setTimeout(resizeModal, 150);
  }

  const PaymentMethodCard = ({ method, selected }) => {
    if (method === "CREDIT_CARD")
      return (
        <button
          onClick={() => selectPaymentMethod("CREDIT_CARD")}
          className={"method px-2 py-1 " + (selected && "checked")}
        >
          <CreditCardIcon color={selected ? "secondary" : "lightGray"} />
          <Mui.Typography
            variant="label"
            color={selected ? "secondary" : "lightGray"}
          >
            Cartão de crédito
          </Mui.Typography>
          {selected ? (
            <RadioButtonCheckedIcon color="secondary" />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </button>
      );

    if (method === "PIX")
      return (
        <button
          onClick={() => selectPaymentMethod("PIX")}
          className={"method px-2 py-3 " + (selected && "checked")}
        >
          <PixIcon color={selected ? "secondary" : "lightGray"} />
          <Mui.Typography
            variant="label"
            color={selected ? "secondary" : "lightGray"}
          >
            PIX
          </Mui.Typography>
          {selected ? (
            <RadioButtonCheckedIcon color="secondary" />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </button>
      );
  };

  const CardInput = ({
    placeholder = "",
    label = "",
    endIcon,
    value,
    onInput,
    autocomplete,
    cardKey,
  }) => {
    return (
      <Mui.TextField
        label={label}
        color="secondary"
        value={value || undefined}
        placeholder={placeholder}
        defaultValue={paymentInfoRef.current[cardKey]}
        onInput={onInput}
        variant="filled"
        fullWidth
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          autocomplete,
        }}
        InputProps={{
          endAdornment: endIcon || null,
        }}
      />
    );
  };

  const CreditCardInputs = () => {
    const [expiration, setExpiration] = useState(
      paymentInfoRef.current["cardExpiration"] || ""
    );

    return (
      <div className="credit-card-inputs mt-2">
        <CardInput
          autocomplete="cc-number"
          label="Número do cartão"
          endIcon={
            <Mui.InputAdornment position="end">
              <CreditCardIcon />
            </Mui.InputAdornment>
          }
          cardKey="cardNumber"
          onInput={(v) => {
            const value = removeNonNumbersFrom(v.target.value);
            paymentInfoRef.current["cardNumber"] = value;
          }}
        />
        <div className="row gap-1 mt-1">
          <CardInput
            cardKey="cardExpiration"
            onInput={(v) => {
              let value = removeNonNumbersFrom(v.target.value);
              if (value.length > 2) {
                // Se a string tiver mais de 2 caracteres, insira uma barra '/' na terceira posição
                const v = value.split("");
                v.splice(2, 0, "/");
                value = v.join("");
              }

              // Remove a barra se o usuário a estiver apagando
              if (
                value.length === 3 &&
                v.inputType === "deleteContentBackward"
              ) {
                const v = value.split("");
                v.splice(2, 1); // Remove a barra
                value = v.join("");
              }
              paymentInfoRef.current["cardExpiration"] = value;
              setExpiration(value);
            }}
            autocomplete="cc-exp"
            label="Validade (mês/ano)"
            placeholder="mm/aa"
            value={expiration}
          />
          <CardInput
            cardKey="cardCcv"
            onInput={(v) => {
              const value = removeNonNumbersFrom(v.target.value);
              paymentInfoRef.current["cardCcv"] = value;
            }}
            autocomplete="cc-csc"
            label="Código de segurança (CCV)"
            placeholder="CCV"
          />
        </div>
      </div>
    );
  };

  if (pixImage)
    return (
      <div className="px-5 pt-4 pb-2 fields-area checkout-area">
        <Mui.Typography
          variant="h3"
          className="slide-bottom-fade-small mb-1 txm"
        >
          Escaneie o QR Code abaixo para finalizar sua assinatura
        </Mui.Typography>
        <img
          src={"data:image/png;base64," + pixImage.image}
          className="pix-image"
        />
        <Mui.Typography
          variant="h3"
          className="slide-bottom-fade-small mt-2 mb-1 txm"
        >
          Ou utilize o PIX copia e cola abaixo
        </Mui.Typography>

        {success && (
          <Mui.Alert
            severity="success"
            className="success-message"
            style={{ borderRadius: 18 }}
          >
            {success}
          </Mui.Alert>
        )}

        {paymentStatus === "PENDING" && (
          <div className="payment-status">
            <Mui.Typography
              variant="h5"
              className="slide-bottom-fade-small mb-1 txm"
            >
              O pagamento ainda não foi confirmado, este processo pode demorar
              alguns minutos.
            </Mui.Typography>
          </div>
        )}

        <Mui.Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={handleCopyQRCode}
          startIcon={<ContentCopyIcon />}
          className="py-1 px-3"
        >
          Copiar PIX
        </Mui.Button>

        <Mui.Button
          variant="outlined"
          size="small"
          onClick={getSubscriptionStatus}
          disabled={loading}
          startIcon={loading ? <Mui.CircularProgress /> : <ReplayIcon />}
          className="py-1 px-3"
        >
          Verificar status
        </Mui.Button>
      </div>
    );

  return (
    <div className="px-5 pt-4 pb-2 fields-area checkout-area">
      <Mui.Typography variant="h3" className="slide-bottom-fade-small mb-1 txm">
        Escolha a forma de pagamento
      </Mui.Typography>

      {plans.map((plan, i) => (
        <Mui.Chip
          className={"plan-info " + (plan.checked && "checked-plan")}
          label={`${plan.name} | R$ ${("" + plan.price.toFixed(2)).replace(
            ".",
            ","
          )} - ${availableCycles[plan.cycle]}`}
          variant="outlined"
          deleteIcon={
            plan.checked ? (
              <RadioButtonCheckedIcon color="secondary" />
            ) : (
              <RadioButtonUncheckedIcon color="lightGray" />
            )
          }
          onDelete={() => selectPlan(i)}
          onClick={() => selectPlan(i)}
        />
      ))}

      {hasPlanSelected && (
        <div className="methods-available mt-2">
          {selectedPlan.paymentMethods.includes("CREDIT_CARD") && (
            <PaymentMethodCard
              method="CREDIT_CARD"
              selected={methodChecked === "CREDIT_CARD"}
            />
          )}
          {selectedPlan.paymentMethods.includes("PIX") && (
            <PaymentMethodCard
              method="PIX"
              selected={methodChecked === "PIX"}
            />
          )}
        </div>
      )}

      {methodChecked === "CREDIT_CARD" && <CreditCardInputs />}

      {methodChecked && (
        <Mui.TextField
          label="CPF"
          color="secondary"
          placeholder="Seu CPF"
          className="mt-2"
          value={cpf}
          onInput={handleCPFInput}
          variant="filled"
          fullWidth
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "off",
          }}
          helperText="Insira o seu número de CPF"
        />
      )}

      {error && (
        <Mui.Alert
          severity="error"
          className="error-message"
          style={{ borderRadius: 18 }}
        >
          {error}
        </Mui.Alert>
      )}

      <Mui.Button
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={loading}
        startIcon={loading && <Mui.CircularProgress size={12} />}
        className="py-2 px-4 mt-2 main-modal-btn"
      >
        {!loading && "Realizar pagamento"}
      </Mui.Button>
    </div>
  );
};
const PaymentConfirmed = ({}) => {
  const viewedRef = useRef();

  useEffect(() => {
    if (!viewedRef.current) {
      Ginalytics.register("payment_confirmed");
      viewedRef.current = true;
    }
  }, []);

  const MobileCard = ({ icon, text, redirectUrl }) => {
    return (
      <div className="download-card px-2 py-3">
        {icon}
        <Mui.Typography
          variant="h4"
          className="txm mt-1 delay-3 slide-bottom-fade-small"
        >
          {text}
        </Mui.Typography>
        <Mui.Button
          variant="contained"
          color="secondary"
          className="mt-1"
          onClick={() => {
            Ginalytics.register("pressed_download", null, text);

            window.open(redirectUrl, "_parent");
          }}
        >
          Baixar App
        </Mui.Button>
      </div>
    );
  };
  return (
    <div className="px-5 pt-4 pb-2 fields-area">
      <Mui.Typography variant="h3" className="txm slide-bottom-fade-small">
        Parabéns!
      </Mui.Typography>
      <Mui.Typography
        variant="h4"
        className="txm slide-bottom-fade-small delay-1"
      >
        Seu pagamento foi confirmado, e{" "}
        <b>a partir de agora sua vida fitness nunca mais será a mesma!</b>
      </Mui.Typography>
      <Mui.Typography
        variant="h4"
        className="txm slide-bottom-fade-small delay-2"
      >
        O próximo passo é <b>baixar o aplicativo</b>, e entrar com o mesmo
        número de celular que você utilizou para se cadastrar
      </Mui.Typography>

      <div className="download-btns">
        <MobileCard
          icon={<AdbIcon />}
          text="Baixar para Android"
          redirectUrl="https://play.google.com/store/apps/details?id=com.ginastee"
        />
        <MobileCard
          icon={<AppleIcon />}
          text="Baixar para iPhone"
          redirectUrl="https://apps.apple.com/br/app/ginastee/id6448853593"
        />
      </div>
    </div>
  );
};

function removeNonNumbersFrom(string) {
  return string.trim().replace(/[^0-9]/g, "");
}

function isCPFValid(cpf) {
  // Remove caracteres não numéricos
  cpf = cpf.replace(/\D/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let firstDigit = (sum * 10) % 11;
  if (firstDigit === 10 || firstDigit === 11) {
    firstDigit = 0;
  }
  if (parseInt(cpf.charAt(9)) !== firstDigit) {
    return false;
  }

  // Calcula o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  let secondDigit = (sum * 10) % 11;
  if (secondDigit === 10 || secondDigit === 11) {
    secondDigit = 0;
  }
  if (parseInt(cpf.charAt(10)) !== secondDigit) {
    return false;
  }

  return true;
}
function isCardExpirationValid(dateString) {
  // Verifica se a string tem o formato "mm/aa" ou "mm/aaaa"
  const dateRegex = /^(0[1-9]|1[0-2])\/(\d{2}|\d{4})$/;
  if (!dateRegex.test(dateString)) {
    return false;
  }

  // Divide a string em mês e ano
  const [month, year] = dateString.split("/").map(Number);

  // Obtém o ano atual com base no formato da entrada (2 ou 4 dígitos)
  const currentYear = new Date().getFullYear();
  const currentYearShort = currentYear % 100;

  // Verifica se o mês está no intervalo válido (01 a 12) e o ano é igual ou posterior ao ano atual
  if (
    month < 1 ||
    month > 12 ||
    (year < currentYearShort && year < currentYear)
  ) {
    return false;
  }

  return true;
}
