import React from "react";

import HeaderNavigation from "../../components/HeaderNavigation";

import * as Material from "@mui/material";

import "./styles.css";

export default function MainComponent({
  children,
  displayHeader = true,
  className = "",
  snackMessage,
}) {

  return (
    <main className={"main-component " + className}>
      {displayHeader && <HeaderNavigation />}
      {children}

      <Material.Snackbar
        open={!!snackMessage}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={null}
        message={snackMessage}
      />
    </main>
  );
}
