import React from "react";

import logo from "../../assets/logo/M_WHITE_LOGO_FULL.svg";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "./styles.css";

export default function Footer({mode='dark'}) {
  return (
    <footer className="mt-8">
      <div className="footer-section social-icons-sections">
        <img src={logo} alt="Ginastee Logo" className="footer-logo" style={{filter: mode === 'dark' ? 'none' : 'invert(1)'}} />
        <div className="social-icons pl-1">
          <a aria-label="Link para o Instagram" href="https://instagram.com/ginastee.app" target="_blank">
            <InstagramIcon />
          </a>
          <a aria-label="Link para o Facebook" href="https://www.facebook.com/ginastee.br" target="_blank">
            <FacebookIcon />
          </a>
          <a aria-label="Link para o Linkedin" href="https://www.linkedin.com/company/ginastee/" target="_blank">
            <LinkedInIcon />
          </a>
        </div>
      </div>
      <div className="footer-section contact">
        <h4 className="mb-1">Entre em contato conosco</h4>
        <a
          href="https://api.whatsapp.com/send/?phone=5531971583279&text&type=phone_number&app_absent=0"
          target="_blank"
          className="mb-1"
        >
          <span>WhatsApp: +55 3197158-3279</span>
        </a>
        <a
          href="mailto:adm@ginastee.com"
          target="_blank"
          className="mb-1"
        >
          <span>Email: adm@ginastee.com</span>
        </a>
      </div>
    </footer>
  );
}
