import axios from 'axios';

import Config from '../config.js'


const api =  {
  get: async function(url, params={}){
    return await handleRequest(url, params, 'get')
  },
  delete: async function(url, params={}){
    return await handleRequest(url, params, 'delete')
  },
  post: async function(url, params={}){
    return await handleRequest(url, params, 'post')
  },
  patch: async function(url, params={}){
    return await handleRequest(url, params, 'patch')
  },

  token: null,

  configure: function(token){
    axios.defaults.baseURL = Config.apiEndpoint
    axios.defaults.headers.withCredentials = true
    prepareToken(token)
  }
}

async function handleRequest(url, params, type){
  try {
    if(!api.token) prepareToken()
    return await axios[type](url, params, {withCredentials: true}) 
  } catch(e){
    return e
  }
}

function prepareToken(tokenLoaded){
  let token = localStorage.getItem('token')

  try{
    token = tokenLoaded ? tokenLoaded : JSON.parse(token)

    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    api.token = token
    return true
  } catch(e){
    return false
  }
}


export default api