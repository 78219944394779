import React, { useRef, useState, useEffect, Suspense } from "react";

import "./styles.css";

import { ReactComponent as AppSVG } from "../../assets/draws/hero-app.svg";
import { ReactComponent as CompositionElmsBg } from "../../assets/draws/hero-elms.svg";
import { ReactComponent as Smoke1 } from "../../assets/bg-effects/smoke-1.svg";
import { ReactComponent as Smoke2 } from "../../assets/bg-effects/smoke-2.svg";
import pageDemo from "../../assets/draws/page-img.png";
import workoutsDemo from "../../assets/draws/workouts-demo.png";
import plansManagerDemo from "../../assets/draws/plans-manager.png";
import appViewsDemo from "../../assets/draws/app-views.png";
import AppBenefits from "../../assets/draws/app-benefits.png";
import AppBenefitsColumn from "../../assets/draws/app-benefits-column.png";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import HeaderNavigation from "../../components/HeaderNavigation";
import Footer from "../../components/Footer";

const windowWidth = window.screen.width;

export default function Home() {
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (windowWidth > 750) {
        var top =
          (window.pageYOffset || document.documentElement.scrollTop) -
          (document.documentElement.clientTop || 0);

        if (top < 1000) {
          /*const scale = top / 6;
        document.querySelector(
          ".hero-app-container"
        ).style.width = `${Math.round(250 + scale)}px`;*/
          document.querySelector(".hero-app-container").style.opacity = `${
            1 - top / 1000 + 0.1
          }`;
        }
      }

      function lerp(start, end, t) {
        return start * (1 - t) + end * t;
      }

      window.addEventListener("scroll", function () {
        let sections = document.querySelectorAll(".demo-section");
        let windowHeight = window.innerHeight;

        sections.forEach((section, index) => {
          if (index === 0) return;
          let rect = section.getBoundingClientRect();
          let offsetTop = rect.top;
          let opacity = 1.0;

          if (offsetTop < windowHeight) {
            opacity = lerp(0.5, 1.0, offsetTop / windowHeight);
          }

          sections[index - 1].style.transform = `scale(${
            0.3 + opacity > 1 ? 1 : 0.3 + opacity
          })`;

          //sections[index-1].style.opacity = opacity.toFixed(2)
        });
      });
    });

    window.scrollTo(0, 0);
  }, []);

  function ctaButtonHandler() {
    window.location.href = "https://adm.ginastee.com/signin";
  }

  return (
      <div className="lp-page">
        <div className="plp">
          <HeaderNavigation
            onLoginPress={() => {
              window.location.href = "https://adm.ginastee.com";
            }}
          />
          <HeroSection />
          <Smoke1 className="hero-bg-effect-2" />
          <Smoke2 className="hero-bg-effect-3" />
          <CustomersSection ctaButtonHandler={ctaButtonHandler} />
          <ProposalSection />
        </div>
        <DemoPaper
          className="paper-page"
          title="Tenha a sua página de personal customizável"
          subtitle="Insira a sua logo, um vídeo de boas vindas, depoimentos de alunos, e mais"
          image={<img src={pageDemo} alt="Benefícios Ginastee" />}
        />
        <DemoPaper
          className="paper-page"
          title="Criação de treinos otimizada"
          subtitle="Crie e envie treinos para seus alunos em poucos minutos"
          image={<img src={workoutsDemo} alt="Benefícios Ginastee" />}
        />
        <DemoPaper
          className="paper-page"
          title="As finanças do seu trabalho como personal de forma diferente"
          subtitle="Precifique seu serviço, disponibilize para seus alunos, e deixe que o Ginastee faça as cobranças"
          image={
            <img
              src={plansManagerDemo}
              className="mt-4"
              alt="Benefícios Ginastee"
            />
          }
        />
        <DemoPaper
          className="paper-page"
          title="O aplicativo que os alunos adoram"
          subtitle="Simples, moderno, e completo, projetado para você entregar mais valor para seus alunos"
          image={
            <img
              src={appViewsDemo}
              className="mt-4"
              alt="Benefícios Ginastee"
            />
          }
        />
        <div className="plp plp-bottom pb-8">
          <AllBenefitsSection />
          <Footer />
        </div>
        <Smoke2 className="hero-bg-effect-3" />
        <CustomButtonMain
          action={ctaButtonHandler}
          icon={<KeyboardArrowRightIcon />}
          className="main-fixed-btn"
          text="Começar agora"
        />
      </div>
  );
}

const HeroSection = () => {
  return (
    <section className="hero-section mt-8 pt-3">
      <div className="hero-content">
        <h1 className="slide-bottom-fade-small">
          Feito para personal trainers que querem encantar seus alunos
        </h1>
        <h4 className="slide-bottom-fade-small delay-1">
          Eleve a experiência de quem está treinando com você
        </h4>
        <div className="hero-app-container">
          <AppSVG className="hero-app mt-3 slide-bottom-fade-small" />
        </div>
      </div>
      <CompositionElmsBg className="hero-bg-effect-1" />
    </section>
  );
};

const CustomersSection = ({ ctaButtonHandler }) => {
  const users = [
    userFactory("Pedro Henrique", 0, 22, 40),
    userFactory("Luiza Almeida", 1, 17, 2),
    userFactory("Ricardo José", 2, 25, 10),
    userFactory("Nathalia Santos", 3, 0, 15),
    userFactory("Luiz Henrique", 4, -10, -25),
    userFactory("Amanda Cardoso", 5, 0, 5),
    userFactory("Júlio Fernandes", 6, 172, -235),
    userFactory("João Luiz", 7, 57, -52),
  ];

  function userFactory(name, i, x, y) {
    return { name, i, x, y };
  }

  return (
    <section className="users-section">
      <h2 className="slide-bottom-fade-small mt-6 mb-2">
        Todos os seus alunos em um só lugar
      </h2>
      <div className="users-area">
        {users.map((user) => (
          <div
            className={"user-chip fade delay-" + user.i}
            key={user.i}
            style={{
              transform: `translate(${user.x}%, ${user.y}%)`,
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL + "/users-avatar/av-" + user.i + ".png"
              }
              alt="Avatar de usuário"
            />
            <h5>{user.name}</h5>
          </div>
        ))}
      </div>
      <CustomButtonSecondary
        action={ctaButtonHandler}
        icon={<KeyboardArrowRightIcon />}
        className="mt-2"
        text="Organizar meus alunos"
      />
    </section>
  );
};
const ProposalSection = () => {
  return (
    <section className="mb-3">
      <h2 className="slide-bottom-fade-small mt-4 mb-3">
        Outras ferramentas te trouxeram até aqui, o Ginastee te levará para o
        próximo nível
      </h2>
    </section>
  );
};

const DemoPaper = ({ title, subtitle, image, className }) => {
  return (
    <section className={"demo-section pb-7 pt-6 px-4 " + className}>
      <span className="scroll-bar-span small mb-3"></span>

      <h2>{title}</h2>
      {subtitle && <h4>{subtitle}</h4>}
      {image}
    </section>
  );
};

const AllBenefitsSection = () => {
  const ImgSrc = windowWidth > 750 ? AppBenefits : AppBenefitsColumn;
  return (
    <section className="all-benefits-section mt-4 px-3 py-2">
      <h1 className="slide-bottom-fade-small pt-3">
        A ferramenta completamente completa
      </h1>
      <h4 className="slide-bottom-fade-small pb-3">
        Pensada nos mínimos detalhes para você entregar a seus alunos uma
        experiência única
      </h4>
      <img src={ImgSrc} className="app-benefits-img" alt="Benefícios do app" />
    </section>
  );
};

const CustomButtonMain = ({ action, className, text, icon }) => {
  return (
    <button onClick={action} className={"custom-button " + className}>
      {icon}
      {text}
    </button>
  );
};
const CustomButtonSecondary = ({ action, className, text, icon }) => {
  return (
    <button onClick={action} className={"custom-button-secondary " + className}>
      {icon}
      {text}
    </button>
  );
};
