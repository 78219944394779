import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import "./styles.css";

import * as Material from "@mui/material";

import HeaderNavigation from "../../components/HeaderNavigation";

import SendIcon from "@mui/icons-material/Send";

import RoundedWrite from "../../assets/draws/rounded-write.svg";
import RoundedPeople from "../../assets/draws/rounded-people.svg";
import RoundedPhone from "../../assets/draws/rounded-phone.svg";
import Arrow from "../../assets/draws/arrow.svg";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import AppViews from "../../assets/draws/app-views.png";

import CustomerFAQArea from "../../components/CustomerFAQArea";
import Footer from "../../components/Footer";

import Ginalytics from "../../helpers/ginalytics.js";

export default function Hub() {
  const trainersSectionRef = React.useRef();

  const registeredPageViewRef = React.useRef();

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    addSrcInParams();

    if (!registeredPageViewRef.current) {
      Ginalytics.register("page_view", null, "home");
      registeredPageViewRef.current = true;
    }
  }, [location.href]);

  const trainers = [
    trainerFactory(
      1580011,
      "https://assets.ginastee.com/trainerMedia/1580011/avatars/zugvi3ffi3zxdwjimj217phpjd.jpeg",
      "Danrley Santos",
      "Te ajudo a alcançar seus objetivos colocando todo meu conhecimento a sua disposição.",
      "cFVPZFExd1IzNHpwK2ZraXBmOUxZQzc4d0lNNkpGLzI5ejljcWpwM1RKdz0=",
      ["Perda de peso", "Saúde"]
    ),

    trainerFactory(
      1580027,
      "https://assets.ginastee.com/trainerMedia/1580027/avatars/zasm13dqhh2giivkbmermzcjmj.jpeg",
      "Marcelo Buri",
      "Perca peso e melhore a  auto estima com treinamento personalizado que já ajudou mais de 100 pessoas.",
      "WUhEYWgrZG9MVFA5YjdXVHN0TjNwL2EzSmtNTnFrd2tLMmxSeDNvMTV4QT0=",
      ["Perda de peso", "Ganho de massa magra", "Estética", "Saúde"]
    ),

    trainerFactory(
      1580037,
      "https://assets.ginastee.com/trainerMedia/1580037/avatars/w3tuyhqcgjn8lafc5cpxcfct0v.jpeg",
      "Keronlayne Corrêa",
      "Presto Consultoria online e treinamentos personalizados.",
      "SXZzdGh1SjIxVHRTNlFlV3huZGpDbGNXYko3d3FJMTBEUlh3R2lZZzE0RT0=",
      [
        "Perda de peso",
        "Ganho de massa magra",
        "Tratamento de lesões",
        "Saúde",
        "Estética",
      ]
    ),

    trainerFactory(
      1580033,
      "https://assets.ginastee.com/trainerMedia/1580033/avatars/hfnsm8wrl7jkrg1jhph3ht02qu.jpeg",
      "João Porto",
      "Te ajudo a emagrecer e ter mais saúde colocando 13 de experiência a sua disposição.",
      "WTNvS2Yva1lBWm12L1ZuZEFrMllTNjIrWk42NnVPQjRKNk0xNG56WHQzUT0=",
      ["Perda de peso", "Saúde"]
    ),

    trainerFactory(
      1580042,
      "https://assets.ginastee.com/trainerMedia/1580042/avatars/fqfcvofoxrmm3yafh5k2amugbh.jpeg",
      "Sanclei Maito",
      "Saia do zero aos 3 km em até 8 semanas, diminua a ansiedade e perca peso.",
      "c2syT0tzdkFWcXlBM0VpVTRrYTF3SndsZ20wOVhLZC8wV1JUZksxV09xTT0=",
      ["Perda de peso", "Saúde"]
    ),
  ];

  const addSrcInParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("user_src", "hub");
    const newSearchString = `?${queryParams.toString()}`;
    navigate(newSearchString);
  };

  function navigateToTrainers(placement = null) {
    Ginalytics.register("clicked_on_navigate_to_trainers", null, placement);
    trainersSectionRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <main className="hub-main">
      <HeaderNavigation />
      <Banner onCTAClick={() => navigateToTrainers("hero")} />
      <h4
        style={{
          textAlign: "center",
          backgroundColor: "white",
          padding: "20px",
          margin: "10px auto",
          borderRadius: "20px",
          maxWidth: '600px',
          width: '96%'
        }}
      >
        Junte-se a mais de outras 2.000 pessoas que se transformaram com o
        Ginastee
      </h4>

      <HowItWorks />
      <TrainersList trainers={trainers} sectionRef={trainersSectionRef} />
      <WhyYouNeedATrainer />
      <section className="dark-section">
        <AppDemo onCTAClick={() => navigateToTrainers("app_demo")} />
        <CustomerFAQArea mode="dark" />
      </section>
      <TrainerInviteCard />
      <Footer mode="light" />
    </main>
  );
}

const Banner = ({ onCTAClick }) => {
  return (
    <div className="banner">
      <div className="banner-content">
        <Material.Typography variant="h1">
          Encontre o personal trainer perfeito para o seu objetivo
        </Material.Typography>
        <Material.Typography variant="h4">
          Os melhores personal trainers com atendimento à distância para te
          ajudar a ir para o próximo nível estão aqui
        </Material.Typography>
      </div>

      <Material.Button
        onClick={onCTAClick}
        className="header-cta px-5 py-2"
        variant="contained"
        startIcon={<SendIcon />}
        color="secondary"
      >
        Começar agora
      </Material.Button>
    </div>
  );
};

const HowItWorks = () => {
  const sections = [
    {
      image: RoundedPeople,
      title: "Escolha um personal",
      subtitle:
        "Selecione o personal que você mais se identifica, pressionando “Visualizar perfil”",
    },
    {
      image: RoundedWrite,
      title: "Se cadastre",
      subtitle:
        "Na página do personal, pressione “Treinar com (nome do personal)”, e insira as informações, é bem rápido",
    },
    {
      image: RoundedPhone,
      title: "Baixe o App",
      subtitle:
        "Seu personal irá entrar em contato, e você receberá as orientações pelo app Ginastee, disponível para Android e IOS",
    },
  ];
  const Step = ({ image, title, subtitle }) => {
    return (
      <div className="hiw-step">
        <img src={image} alt="Benefícios do app" />
        <Material.Typography variant="h3">{title}</Material.Typography>
        <Material.Typography variant="subtitle">{subtitle}</Material.Typography>
      </div>
    );
  };

  return (
    <div className="hiw my-2">
      <Material.Typography variant="h3">Como funciona? </Material.Typography>
      <span className="hiw-divider"></span>

      <div className="hiw-sections mt-2">
        <div className="hiw-arrows">
          <img src={Arrow} alt="_" />
          <img src={Arrow} alt="_" />
        </div>
        {sections.map((section, i) => (
          <Step {...section} key={i} />
        ))}
      </div>
    </div>
  );
};

const TrainersList = ({ trainers, sectionRef }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const Trainer = ({
    id,
    image,
    name,
    description,
    url,
    specialties,
    position,
  }) => {
    function goToTrainerPage(e, placement) {
      e.stopPropagation();
      Ginalytics.register(
        "clicked_on_trainer_banner",
        {
          order: position,
          placement,
        },
        id
      );

      navigate("/trainer/" + url + location.search);
    }
    return (
      <div className="tc" onClick={(e) => goToTrainerPage(e, "card")}>
        <img src={image} className="avatar" alt="avatar" />
        <div className="details">
          <Material.Typography variant="h3">{name}</Material.Typography>
          <Material.Typography variant="subtitle">
            {description}
          </Material.Typography>
          <div className="specialties mt-1 mb-1">
            {specialties.map((specialty, i) => (
              <Material.Chip size="small" key={i} label={specialty} />
            ))}
          </div>
          <Material.Button
            onClick={(e) => goToTrainerPage(e, "button")}
            startIcon={<OpenInNewIcon />}
            className="go-to-profile-btn"
          >
            Visualizar perfil
          </Material.Button>
        </div>
      </div>
    );
  };

  return (
    <section className="tl" ref={sectionRef}>
      <Material.Typography variant="h2" className="section-title mb-2">
        Escolha um dos personal trainers disponíveis, e eles te ajudarão a
        atingir seus objetivos
      </Material.Typography>

      {trainers.map((trainer, i) => (
        <Trainer {...trainer} key={i} position={i} />
      ))}
    </section>
  );
};

const WhyYouNeedATrainer = () => (
  <section className="wynt">
    <Material.Typography variant="h3" className="mb-2 mt-3">
      Por que você precisa de um personal trainer?
    </Material.Typography>
    <Material.Typography variant="h5" className="mb-1">
      Ter um acompanhamento de um profissional, garantirá que você vai utilizar
      as técnicas corretas, e isso fará com que você acelere seus resultados.
    </Material.Typography>
    <Material.Typography variant="h5" className="mb-1">
      Treinar com um personal te ajuda a ter mais motivação nos treinos, isso e
      faz com que você não desista dos seus objetivos
    </Material.Typography>
    <Material.Typography variant="h5" className="mb-1">
      Um acompanhamento personalizado para você reduz as chances de lesões
    </Material.Typography>
  </section>
);

const AppDemo = ({ onCTAClick }) => {
  return (
    <div className="app-demo mt-4 mb-6">
      <Material.Typography variant="h2">
        Você alcançará seus objetivos através do aplicativo mais completo do
        momento
      </Material.Typography>
      <Material.Typography variant="h5">
        Tenha o acompanhamento que você merece, e potencialize seus resultados
        com a ajuda de uma tecnologia projetada para te levar para o próximo
        nível
      </Material.Typography>
      <img
        src={AppViews}
        className="app-views mt-3"
        alt="Telas do aplicativo Ginastee"
      />
      <Material.Button
        onClick={onCTAClick}
        className="px-5 py-2 mt-6"
        variant="contained"
        startIcon={<SendIcon />}
        color="secondary"
      >
        Começar agora
      </Material.Button>
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="app-demo mt-4">
      <Material.Typography variant="h2">
        Você alcançará seus objetivos através do aplicativo mais completo do
        momento
      </Material.Typography>
      <Material.Typography variant="h5">
        Tenha o acompanhamento que você merece, e potencialize seus resultados
        com a ajuda de uma tecnologia projetada para te levar para o próximo
        nível
      </Material.Typography>
      <img
        src={AppViews}
        alt="Telas do aplicativo Ginastee"
        className="app-views mt-3"
      />
    </div>
  );
};

const TrainerInviteCard = () => {
  return (
    <div className="tic px-4 py-3 mt-3">
      <Material.Typography className="text" variant="h2">
        É personal trainer?{" "}
      </Material.Typography>
      <Material.Typography className="text" variant="h5">
        Para fazer parte do time de personal trainers do Ginastee é necessário
        criar um perfil, e passar pela análise de enquadramento{" "}
      </Material.Typography>
      <Material.Button
        variant="contained"
        className="mt-2 px-4 py-2"
        color="white"
        onClick={() => {
          Ginalytics.register(
            "trainer_pressed_signin",
            {
              placement: "hub_card",
            },
            window.location.pathname
          );
          window.location.href = "https://ad.ginastee.com/signin";
        }}
      >
        Fazer aplicação
      </Material.Button>
    </div>
  );
};

function trainerFactory(id, image, name, description, url, specialties) {
  return {
    id,
    image,
    name,
    description,
    url,
    specialties,
  };
}
