const env = 'prod'

const Config = {
  prod: {
    apiEndpoint: 'https://api.ginastee.com/api'
  },
  home: {
    apiEndpoint: 
      //'http://localhost:8000/api',  
      'https://api-test.ginastee.com/api' 
  },
  apiToken: getToken()
}

function getToken(){
  try{
    const token = JSON.parse(localStorage.getItem('token'))
    if(!token) return false
    return token
  } catch(e){
    return false
  }
}

export default Config[env]

export const environment = env

export const token = Config.apiToken
