import React, { useState, useEffect, useRef, useMemo } from "react";

import logo from "../../assets/logo/M_WHITE_LOGO_FULL.svg";

import * as Mui from "@mui/material";

import "./styles.css";

import LoginIcon from "@mui/icons-material/Login";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlaceIcon from "@mui/icons-material/Place";
import CheckIcon from "@mui/icons-material/Check";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PixIcon from "@mui/icons-material/Pix";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReplayIcon from "@mui/icons-material/Replay";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";
import SendIcon from "@mui/icons-material/Send";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Stars from "../../assets/draws/five-stars.svg";

import Ginalytics from "../../helpers/ginalytics.js";

import DisplayTextWithLineBreaks from "../DisplayTextWithLineBreaks";

import SignInModal from "../SignInModal";

export default function TrainerCard(params) {
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [photoOpened, setPhotoOpened] = useState(null);

  const slug = "";

  function handleStartSignin(planData, planIndex) {
    setSignInModalOpen(true);
  }

  return (
    <>
      <div className="ptc">
        <div className="badge">
          <Mui.Typography variant="label">PREMIUM PERSONAL</Mui.Typography>
        </div>

        <div className="header mb-2">
          <img
            width="72"
            height="72"
            className="avatar"
            src={params.avatar}
            onClick={() => setPhotoOpened(params.avatar)}
          />
          <div>
            <Mui.Typography variant="h4">{params.trainerName}</Mui.Typography>
            <img className="stars" src={Stars} />
          </div>
        </div>

        <DisplayTextWithLineBreaks text={params.longDescription} />

        {params.depoiments && params.depoiments.length > 0 && (
          <div className="depoiment-area mt-2">
            {params.depoiments?.map((url, i) => (
              <img
                onClick={() => setPhotoOpened(url)}
                width="50"
                src={url}
                className="depoiment-photo"
              />
            ))}
          </div>
        )}

        {params?.plans?.length && (
          <div className="mt-4 available-plans">
            <Mui.Typography variant="h4">Planos disponíveis:</Mui.Typography>
            <div className="plans-list mt-1">
              {params?.plans.map((plan, i) => (
                <Mui.Chip
                  key={i}
                  className="plan-info mb-1"
                  avatar={<RadioButtonCheckedIcon />}
                  label={`${availableCycles[plan.cycle]} R$ ${(
                    "" + plan.price.toFixed(2)
                  ).replace(".", ",")}`}
                  variant="outlined"
                  deleteIcon={<Mui.Typography></Mui.Typography>}
                  onClick={() => handleStartSignin(params, i)}
                  onDelete={() => handleStartSignin(params, i)}
                />
              ))}
            </div>
          </div>
        )}

        <Mui.Button
          onClick={() => {
            handleStartSignin(params);
          }}
          variant="contained"
          color="secondary"
          startIcon={<SendIcon />}
          className="py-2 px-4 mt-2 cta-btn"
          style={{ width: "100%" }}
          size="large"
        >
          Treinar com {params?.trainerName.split(" ")[0]}
        </Mui.Button>
      </div>

      <SignInModal
        trainerName={params?.trainerName.split(" ")[0]}
        onOpen={() => setSignInModalOpen(true)}
        plans={params?.plans}
        slug={params.slug}
        open={signInModalOpen}
        onClose={() => setSignInModalOpen(false)}
      />

      {photoOpened && (
        <Mui.Modal onClose={() => setPhotoOpened(null)} open={photoOpened}>
          <>
            <img className="modal-photo" src={photoOpened} />
            <Mui.Button
              onClick={() => {
                setPhotoOpened(null);
              }}
              variant="contained"
              color="secondary"
              className="py-3 px-6 mt-2  close-modal-btn"
              size="large"
            >
              Fechar visualização
            </Mui.Button>
          </>
        </Mui.Modal>
      )}
    </>
  );
}

const availableCycles = {
  WEEKLY: "Semanal",
  MONTHLY: "Mensal",
  QUARTERLY: "Trimestral",
  SEMIANNUALLY: "Semestral",
  YEARLY: "Anual",
};
