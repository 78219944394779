import Trainer from "./pages/Trainer";
import Home from "./pages/Home";
import Hub from './pages/Hub'

import Hub2 from './pages/Hub/indexV2'

import Company from './pages/Company'



import EmagrecimentoV1 from './pages/Hub/v2/emagrecimento/V1'



import { useNavigate } from "react-router-dom";

import "./App.css";
import "./assets/spacing.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

import React, { useState, useEffect, lazy } from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { deDE as coreDeDE } from "@mui/material/locale";

import Config, { token } from "./config.js";

import axios from "axios";


const theme = createTheme({
  palette: {
    mode: "light", // set mode to light
    primary: {
      light: "#F6F7F9",
      main: "#121314",
      dark: "#000",
      contrastText: "#000",
    },
    secondary: {
      light: "#E3F3FD",
      main: "#138CE8",
      dark: "#0B7AD5",
      contrastText: "#F6F7F9",
    },
    lightGray: {
      main: "#BCC1CC",
    },
    darkGray: {
      main: "#323334",
    },
    white: {
      main: "#F6F7F9",
    },
    whiteBg: {
      main: "#E3F3FD",
    },
  },
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
    h1: {
      fontSize: 50,
      lineHeight: 1.1,
      letterSpacing: -2,
      fontWeight: 600,
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-cellCheckbox .MuiDataGrid-checkboxInput": {},
          "& .MuiCheckbox-root.Mui-checked": {
            color: "#138CE8", // set the checkbox color to the custom blue color when checked
          },
        },
        row: {
          "&.Mui-selected": {
            backgroundColor: "rgba(44,154,246,0.2)",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "rgba(97,183,252,0.2)",
          },
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(130,197,252,0.2)", // set the hover background color to light blue
          },
        },
      },
    },
  },
  coreDeDE,
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!token?.AccessToken);

  axios.defaults.baseURL = Config.apiEndpoint;
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token?.AccessToken}`,
  };
  axios.defaults.headers.withCredentials = true;

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/trainer/:slug" element={<Trainer />} />

            <Route path="/ev1" element={<EmagrecimentoV1 />} />

            <Route path="/old" element={<Hub />} />
            <Route path="/personal" element={<Home />} />
            <Route path="/" element={<Company />} />



          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;

const GoToDefaultTrainer = () => {
  useEffect(() => {
    window.location.href =
      "https://ginastee.com/trainer/cFVPZFExd1IzNHpwK2ZraXBmOUxZQzc4d0lNNkpGLzI5ejljcWpwM1RKdz0=";
  }, []);
  return <></>;
};

const LoadingComponent = () => {
  return <div>Loading...</div>;
};

const RedirectToHome = () => {
  const navigate = useNavigate();

  useEffect(() => navigate("/"), []);

  return <></>;
};
const RedirectToLogin = () => {
  const navigate = useNavigate();

  useEffect(() => navigate("/login"), []);

  return <></>;
};
