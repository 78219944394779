import * as Mui from "@mui/material";


export default function DisplayTextWithLineBreaks({ text }) {
  const textWithLineBreaks = text.split("\n").map((line, index) => (
    <div key={index} style={{marginTop: '4px'}}>
      {line}
      <br />
    </div>
  ));

  return <Mui.Typography variant="p">{textWithLineBreaks}</Mui.Typography>;
}
